import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { getAllInvestorsRequest, refreshUserPortfolioRequest, swapInvestorsOrderRequest } from '../services'
import { AdminLayout } from '../layouts'
import {
  AddInvestorForm,
  Button,
  Container,
  EditInvestorForm,
  IconButton,
  Modal,
  Table,
  Typography
} from '../components'
import { formatPrice, formatToStockPrice, isNegativeNumber } from '../utils'
import { SCREEN_WIDTH } from '../theme/screen-width.constant'
import { useMediaQuery } from '../hooks'
import { ROUTE } from '../routing'
import { EditIcon, RefreshIcon } from '../icons'


const StyledTop = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
`

const StyledFullNameCellInner = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
  grid-template-columns: auto auto;
  grid-column-gap: 5px;
`

const StyledAddInvestorButtonDekstop = styled.button`
  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    display: none;
  }
`

const StyledAddInvestorButtonMobileWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px auto;
  position: sticky;
  position: -webkit-sticky;
  bottom: 64px;

  button {
    min-width: 233px;
  }

  @media (min-width: ${({ theme }) => theme.screenWidth.mobileMinWidth}) {
    display: none;
  }
`

export const InvestorsPage = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const isMobileScreen = useMediaQuery(`(max-width: ${SCREEN_WIDTH.mobile})`)

  const [addInvestorFormShown, setAddInvestorFormShown] = useState(false)
  const [editUserId, setEditUserId] = useState(null)
  const [investors, setInvestors] = useState(null)
  const [investorsLoading, setInvestorsLoading] = useState(false)
  const [refreshingInvestorsIds, setRefreshingInvestorsIds] = useState([])

  const refetchInvestors = () => {
    setInvestorsLoading(true)
    getAllInvestorsRequest()
      .then(setInvestors)
      .finally(() => setInvestorsLoading(false))
  }

  useEffect(() => {
    refetchInvestors()
  }, [])

  const openAddInvestorModal = () => {
    if (isMobileScreen) {
      navigate(ROUTE.addInvestor)
    } else {
      setAddInvestorFormShown(true)
    }
  }

  const closeAddInvestorModal = () => {
    setAddInvestorFormShown(false)
  }

  const handleInvestorAdded = () => {
    closeAddInvestorModal()
    refetchInvestors()
  }


  const openEditInvestorModal = (userId) => {
    if (isMobileScreen) {
      navigate(ROUTE.editInvestor + '/' + userId)
    } else {
      setEditUserId(userId)
    }
  }

  const refreshInvestorScrape = (investor) => {
    setRefreshingInvestorsIds([...refreshingInvestorsIds, investor.id])

    refreshUserPortfolioRequest({ userId: investor.userId })
      .then((newData) => {
        setInvestors(investors.map((inv) => inv.id === investor.id ? { ...inv, ...newData } : inv))
      })
      .finally(() => {
        setRefreshingInvestorsIds(refreshingInvestorsIds.filter(id => id !== investor.id))
      })
  }

  const closeEditInvestorModal = () => {
    setEditUserId(null)
  }

  const handleInvestorEdited = () => {
    closeEditInvestorModal()
    refetchInvestors()
  }

  return (
    <AdminLayout>
      <StyledTop>
        <Typography variant="page-title">{t('investorsList')}</Typography>

        <Button as={StyledAddInvestorButtonDekstop} variant="white" iconBefore={(
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.81 0C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0H5.81ZM10.75 10.75V16C10.75 16.41 10.41 16.75 10 16.75C9.59 16.75 9.25 16.41 9.25 16V10.75H4C3.59 10.75 3.25 10.41 3.25 10C3.25 9.59 3.59 9.25 4 9.25H9.25V4C9.25 3.59 9.59 3.25 10 3.25C10.41 3.25 10.75 3.59 10.75 4V9.25H16C16.41 9.25 16.75 9.59 16.75 10C16.75 10.41 16.41 10.75 16 10.75H10.75Z"
                  fill="black"/>
          </svg>
        )} onClick={openAddInvestorModal}>{t('addInvestor')}</Button>
      </StyledTop>

      <br/>

      <Table
        draggableRows
        loading={investorsLoading}
        smartResponsive
        onRowClick={(_, index) => openEditInvestorModal(investors[index].userId)}
        onRowDragEnd={(fromIndex, toIndex) => {
          const from = investors[fromIndex]
          const to = investors[toIndex]

          if (from && to) {
            swapInvestorsOrderRequest(from.id, to.id)

            const newInvestors = [...investors]
            newInvestors[fromIndex] = to
            newInvestors[toIndex] = from
            setInvestors(newInvestors)
          }
        }}
        head={[
          '#',
          t('investorName'),
          t('investmentAmount'),
          t('currentBalance'),
          t('revenueFor24Hours'),
          t('totalRevenueForAllTime'),
          '',
        ]}
        body={investors?.map((investor, index) => [
          index + 1,
          (<StyledFullNameCellInner>
            {investor.fullName}
            {investor.banned &&
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1_985)">
                  <path
                    d="M10 18C10 13.5817 13.5817 10 18 10H24C28.4183 10 32 13.5817 32 18V24C32 28.4183 28.4183 32 24 32H18C13.5817 32 10 28.4183 10 24V18Z"
                    fill="#3B82F6"/>
                </g>
                <path
                  d="M19.1494 14H22.8694L22.2494 23.14H19.7694L19.1494 14ZM21.0094 28.16C20.4627 28.16 20.0027 27.9867 19.6294 27.64C19.2694 27.28 19.0894 26.8467 19.0894 26.34C19.0894 25.8333 19.2694 25.4133 19.6294 25.08C19.9894 24.7333 20.4494 24.56 21.0094 24.56C21.5694 24.56 22.0294 24.7333 22.3894 25.08C22.7494 25.4133 22.9294 25.8333 22.9294 26.34C22.9294 26.8467 22.7427 27.28 22.3694 27.64C22.0094 27.9867 21.556 28.16 21.0094 28.16Z"
                  fill="white"/>
              </svg>}
          </StyledFullNameCellInner>),
          investor.initialInvestment,
          formatPrice(investor.currentBalance, {
            showPositiveSign: false,
          }),
          <Typography
            color={isNegativeNumber(investor.revenue24h) ? 'danger' : 'success'}>{formatToStockPrice(investor.revenue24h, investor.revenue24hPercent)}</Typography>,
          <Typography
            color={isNegativeNumber(investor.revenueTotal) ? 'danger' : 'success'}>{formatToStockPrice(investor.revenueTotal, investor.revenueTotalPercent, true)}</Typography>,
          <IconButton loading={refreshingInvestorsIds.includes(investor.id)} onClick={(e) => {
            e.stopPropagation()
            refreshInvestorScrape(investor)
          }}>
            <RefreshIcon/>
          </IconButton>,
          <IconButton onClick={() => openEditInvestorModal(investor.userId)}>
            <EditIcon/>
          </IconButton>
        ])}
      />

      <StyledAddInvestorButtonMobileWrapper>
        <Button variant="white" iconBefore={(
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.81 0C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0H5.81ZM10.75 10.75V16C10.75 16.41 10.41 16.75 10 16.75C9.59 16.75 9.25 16.41 9.25 16V10.75H4C3.59 10.75 3.25 10.41 3.25 10C3.25 9.59 3.59 9.25 4 9.25H9.25V4C9.25 3.59 9.59 3.25 10 3.25C10.41 3.25 10.75 3.59 10.75 4V9.25H16C16.41 9.25 16.75 9.59 16.75 10C16.75 10.41 16.41 10.75 16 10.75H10.75Z"
                  fill="black"/>
          </svg>
        )} onClick={openAddInvestorModal}>{t('addInvestor')}</Button>
      </StyledAddInvestorButtonMobileWrapper>

      <Modal shown={addInvestorFormShown} onClose={closeAddInvestorModal}>
        <Container>
          <AddInvestorForm onAdded={handleInvestorAdded}/>
        </Container>
      </Modal>

      <Modal shown={editUserId} onClose={closeEditInvestorModal}>
        <Container>
          <EditInvestorForm userId={editUserId} onEdited={handleInvestorEdited}/>
        </Container>
      </Modal>
    </AdminLayout>
  )
}