const INVESTORS = '/investors'

export const ROUTE = {
  home: '/',
  login: '/login',
  dashboard: '/dashboard',
  investors: INVESTORS,
  settings: '/settings',
  portfolio: '/portfolio',
  banned: '/banned',
  website: '/website',

  addInvestor: INVESTORS + '/add',
  editInvestor: INVESTORS + '/edit',
}

export const DEFAULT_INVESTOR_ROUTE = ROUTE.portfolio
export const DEFAULT_ADMIN_ROUTE = ROUTE.dashboard