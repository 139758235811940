import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { getMyPortfolioRequest } from '../services'
import { InvestorLayout } from '../layouts'
import { Button, DetailsItem, Paper, Spinner, Typography } from '../components'
import { isNegativeNumber } from '../utils'
import { AuthorizationContext } from '../contexts'
import { useMediaQuery } from '../hooks'
import { SCREEN_WIDTH } from '../theme/screen-width.constant'
import { StocksLineChart } from '../components/StocksLinearChart.component'
import { InvestmentCategoriesDonutChart } from '../components/InvestmentCategoriesDonutChart.component'


const StyledRoot = styled.div`
  min-height: 100%;
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: ${({$showPersonalCabinetMessageBlock}) => $showPersonalCabinetMessageBlock ? 'auto auto auto 1fr auto' : 'auto auto 1fr auto'};

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }
`

const StyledMainDetails = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: ${({ $hasBlockMessage }) => $hasBlockMessage ? '1fr 1fr 1fr' : '1fr 1fr'};

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`

const StyledInvestmentDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 26px;

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`


const StyledButtonRefreshData = styled.button`
  z-index: 1;
  position: sticky;
  position: -webkit-sticky;
  bottom: 15px;
  
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  
  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    max-width: 100%;
  }
`

const StyledPersonalCabinetMessage = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
  justify-items: center;

  word-break: break-word;
`

const StyledChartsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  
  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    grid-template-columns: 1fr !important;
    grid-row-gap: 20px;
  }
`

const StyledChartWrapper = styled.div`
  min-width: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  align-content: start;
`


export const PortfolioPage = () => {
  const { t } = useTranslation()
  const { me, refetchMe } = useContext(AuthorizationContext)
  const isMobileScreen = useMediaQuery(`(max-width: ${SCREEN_WIDTH.mobile})`)

  const [loading, setLoading] = useState(true)
  const [refreshing, setRefreshing] = useState(false)
  const [portfolioData, setPortfolioData] = useState(null)

  const refreshData = () => {
    setRefreshing(true)

    const chartPromise = getMyPortfolioRequest({ forceRefresh: true }).then(setPortfolioData)
    const mePromise = refetchMe()

    Promise.all([
      chartPromise,
      mePromise
    ]).finally(() => setRefreshing(false))
  }

  useEffect(() => {
    setLoading(true)

    getMyPortfolioRequest()
      .then(setPortfolioData)
      .finally(() => setLoading(false))
  }, [])

  const hasBlockMessage = portfolioData?.portfolioMessageBlockTitle && portfolioData?.portfolioMessageBlockText

  const refreshButton = <Button as={StyledButtonRefreshData} loading={refreshing} variant="success" onClick={refreshData}>
    {t('refreshData')}
  </Button>

  const showPersonalCabinetMessageBlock = !!me?.investor?.personalCabinetMessage

  return (
    <InvestorLayout>
      <StyledRoot $showPersonalCabinetMessageBlock={showPersonalCabinetMessageBlock}>
        <StyledMainDetails $hasBlockMessage={hasBlockMessage}>
          <DetailsItem caption={t('investor')} value={loading ? <Spinner/> : portfolioData?.fullName}/>
          <DetailsItem caption={t('investmentAmount')}
                       value={loading ? <Spinner/> : portfolioData?.initialInvestment + ' ' + t('usd')}/>
          {hasBlockMessage && <DetailsItem caption={portfolioData?.portfolioMessageBlockText}
                                           value={loading ? <Spinner/> : portfolioData?.portfolioMessageBlockTitle}/>}
        </StyledMainDetails>

        <StyledInvestmentDetails>
          <DetailsItem size="small-dark" caption={t('currentBalance')}
                       value={loading ? <Spinner/> : portfolioData?.currentBalance + ' ' + t('usd')}/>
          <DetailsItem
            size="small-dark"
            caption={(
              <>
                {t('portfolioChangeFor24Hours')}
                <Typography
                  color={isNegativeNumber(portfolioData?.revenue24hPercent) ? 'danger' : 'success'}> ({portfolioData?.revenue24hPercent}%)</Typography>
              </>
            )}
            value={loading ? <Spinner/> : portfolioData?.revenue24h + ' ' + t('usd')}
          />
          <DetailsItem
            size="small-dark"
            caption={(
              <>
                {t('totalRevenue')}
                <Typography
                  color={isNegativeNumber(portfolioData?.revenueTotalPercent) ? 'danger' : 'success'}> ({portfolioData?.revenueTotalPercent}%)</Typography>
              </>
            )}
            value={loading ? <Spinner/> : portfolioData?.revenueTotal + ' ' + t('usd')}
          />
        </StyledInvestmentDetails>

        {showPersonalCabinetMessageBlock && <Paper as={StyledPersonalCabinetMessage}>
          {me.investor.personalCabinetMessage}
          <Typography as="div" variant="small-caption">{t('msgForInvestor')}</Typography>
        </Paper>}

        <StyledChartsWrapper style={{
          gridTemplateColumns: me?.investor?.displayInvestmentCategoriesInPortfolio && me?.investor?.displayStocksChartInPortfolio ? '1fr 1fr' : '1fr'
        }}>
          {me?.investor?.displayInvestmentCategoriesInPortfolio &&
            <Paper as={StyledChartWrapper}>
              <Typography align="center" color="primary" variant="big-caption">{t('investmentCategories')}</Typography>
              {loading ? <Spinner/> : <InvestmentCategoriesDonutChart hideLegendWithZeroValue data={portfolioData}/>}
            </Paper>}
          {me?.investor?.displayStocksChartInPortfolio &&
          <Paper as={StyledChartWrapper}>
            <Typography color='primary' align='center' variant='big-caption'>{t('portfolioChart')}</Typography>
            {loading ? <Spinner/> : <StocksLineChart chartData={portfolioData?.chartData}/>}
          </Paper>}
        </StyledChartsWrapper>

        {!loading && !isMobileScreen && refreshButton}
        {!loading && isMobileScreen && refreshButton}
      </StyledRoot>
    </InvestorLayout>
  )
}
