import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Highcharts from 'highcharts'

import { Typography } from './Typography.component'
import { DATE_PERIOD_TIMESTAMPS, PERIOD_DEFAULT, PeriodSelect } from './PeriodSelect.component'
import { LinearChart } from './LinearChart.component'
import { classNames } from '../utils'


// Make Highcharts lines smooth
Highcharts.seriesTypes.line.prototype.getPointSpline = Highcharts.seriesTypes.spline.prototype.getPointSpline

const LOCAL_STORAGE_PERIOD_KEY = 'portfolioPeriod'

let periodLocalStorage = localStorage.getItem(LOCAL_STORAGE_PERIOD_KEY)
if (!Object.keys(DATE_PERIOD_TIMESTAMPS).includes(periodLocalStorage)) {
  localStorage.setItem(LOCAL_STORAGE_PERIOD_KEY, PERIOD_DEFAULT)
  periodLocalStorage = PERIOD_DEFAULT
}

const StyledRoot = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  justify-items: center;
  align-content: flex-start;
  align-items: flex-start;
  grid-row-gap: 20px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

export const StocksLineChart = ({ disabled, chartData }) => {
  const { t } = useTranslation()

  const [periodKey, setPeriodKey] = useState(periodLocalStorage)

  const updatePeriodKey = (newPeriodKey) => {
    setPeriodKey(newPeriodKey)
    localStorage.setItem(LOCAL_STORAGE_PERIOD_KEY, newPeriodKey)
  }

  const period = DATE_PERIOD_TIMESTAMPS[periodKey]
  const chartDataFilteredByPeriod = chartData?.filter(({ time }) => time > period).map(({ USD }) => USD)

  const chartDataExists = !!chartData?.length

  return (
    <StyledRoot className={classNames({disabled})}>
      <PeriodSelect disabled={disabled || !chartDataExists} periodKey={periodKey} setPeriodKey={updatePeriodKey}/>

      {chartDataExists ? <LinearChart data={chartDataFilteredByPeriod}/> :
        <Typography>{t('chartDataIsEmpty')}</Typography>}
    </StyledRoot>
  )
}