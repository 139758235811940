import { useNavigate, useParams } from 'react-router-dom'

import { AdminLayout } from '../layouts'
import { EditInvestorForm} from '../components'
import { ROUTE } from '../routing'
import { useScrollToTop } from '../hooks/useScrollToTop'


export const EditInvestorPage = () => {
  const { userId } = useParams()
  const navigate = useNavigate()

  const handleEditSuccess = () => {
    navigate(ROUTE.investors)
  }

  useScrollToTop()

  return (
    <AdminLayout parentRoute={ROUTE.investors}>
      <EditInvestorForm userId={userId} onEdited={handleEditSuccess} />
    </AdminLayout>
  )
}
