import { toast } from 'react-toastify'
import i18n from 'i18next'

import { API_ORIGIN } from './constants.api'
import { getLocalStorageToken } from '../local-storage'
import { USER_UNAUTHORIZED_REQUEST_CUSTOM_EVENT } from '../../custom-events'


export const makeRequest = async (route, options) => {
  const init: RequestInit = {
    headers: {
      Authorization: getLocalStorageToken(),
    },
  }

  if (options) {
    init.method = options.method || 'GET'

    if (options.body) {
      init.headers['Content-Type'] = 'application/json'
      init.body = JSON.stringify(options.body)
    }
  }

  const response = await fetch(API_ORIGIN + route, init)

  if (response.ok) {
    return await response.json()
  } else {
    if (response.status === 403) {
      // skip the toast message when user becomes banned
      return
    }

    if (response.status === 401) {
      const toastId = 'unauthorized'

      const isActive = toast.isActive(toastId);
      // if toast is already active, just start the timer again by updating the toast
      // (no need to create a new toast to prevent duplicate toasts populating the user screen)
      if (isActive) {
        toast.update(toastId);
      } else {
        toast.error('Unauthorized', {
          toastId: toastId,
        })
      }

      window.dispatchEvent(new Event(USER_UNAUTHORIZED_REQUEST_CUSTOM_EVENT));
      return
    }

    let responseMessage

    try {
      const responseJson = await response.json()
      responseMessage = i18n.t(responseJson.error || responseJson.message)
    } catch (error) {
      responseMessage = response.statusText
    }

    const toastId = responseMessage

    const isActive = toast.isActive(toastId);
    // if toast is already active, just start the timer again by updating the toast
    // (no need to create a new toast to prevent duplicate toasts populating the user screen)
    if (isActive) {
      toast.update(toastId);
    } else {
      toast.error(responseMessage, {
        toastId: responseMessage,
      })
    }
  }
}
