import styled from 'styled-components'

import { Container, Header } from '../components'
import { Helmet } from 'react-helmet'
import { useRef, useEffect } from 'react'
import { useMouseMoveGradientBackground } from '../hooks'


const StyledRoot = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
`

const StyledContent = styled.div`
  animation: example 0.4s forwards;

  @keyframes example {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export const InvestorLayout = ({ children }) => {
  const contentRef = useRef()
  useMouseMoveGradientBackground(contentRef)

  return (
    <StyledRoot>
      <Helmet>
        <title>Investor</title>
      </Helmet>

      <Header/>

      <StyledContent ref={contentRef}>
          <Container>
            {children}
          </Container>

      </StyledContent>
    </StyledRoot>
  )
}