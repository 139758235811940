import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import { Typography } from './Typography.component'
import { Field } from './Field.component'
import {
  deleteInvestorByUserIdRequest, deleteUserRequest,
  getInvestorByUserIdRequest,
  getUserRequest,
  postInvestorByUserIdRequest,
  postUserRequest
} from '../services'
import { FormSection } from './FormSection.component'
import { CopyButton } from './CopyButton.component'
import {
  FieldDisplayInvestmentCategoriesInPortfolio,
  FieldDisplayStocksChartInPortfolio,
  FieldInitialInvestment, FieldInvestmentCategories,
  FieldInvestorName,
  FieldLogin, FieldPersonalCabinetMessage,
  FieldPortfolioLink, FieldPortfolioMessageBlockText, FieldPortfolioMessageBlockTitle,
  FieldPublicPassword
} from './Fields.component'
import { TextArea } from './TextArea.component'
import { HorizontalDivider } from './HorizontalDivider.component'
import { Button } from './Button.component'
import { ConfirmationDialog } from './ConfirmationDialog.component'
import { Spinner } from './Spinner.component'
import { scrapeLinkRequest } from '../services/api/scrape-link.api'
import { StocksLineChart } from './StocksLinearChart.component'


const StyledRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
`

const StyledForm = styled.form`
  display: grid;
  grid-row-gap: 30px;
`

const StyledActionsSection = styled.div`
  display: grid;
  justify-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    
    button {
      width: 100%;
    }
  }
`

export const EditInvestorForm = ({ userId, onEdited }) => {
  const { t } = useTranslation()

  const refForm = useRef()
  const banMessageRef = useRef()

  const [userLoading, setUserLoading] = useState(false)
  const [user, setUser] = useState(null)

  const [investorLoading, setInvestorLoading] = useState(false)
  const [investor, setInvestor] = useState(null)

  const [chartData, setChartData] = useState(null)
  const [chartDataLoading, setChartDataLoading] = useState(false)

  const [restoreLoading, setRestoreLoading] = useState(false)
  const [showRestoreDialog, setShowRestoreDialog] = useState(false)

  const [deleteLoading, setDeleteLoading] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [banLoading, setBanLoading] = useState(false)
  const [showBanDialog, setShowBanDialog] = useState(false)
  const [banReason, setBanReason] = useState('')

  const [displayInvestmentCategoriesInPortfolio, setDisplayInvestmentCategoriesInPortfolio] = useState(true)
  const [displayStocksChartInPortfolio, setDisplayStocksChartInPortfolio] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
  }


  const submitInvestorMainInfo = () => {
    const formData = new FormData(refForm.current)
    const payload = Object.fromEntries(formData)

    return postInvestorByUserIdRequest(userId, {
      fullName: payload.fullName,
      initialInvestment: payload.initialInvestment,
      portfolioMessageBlockTitle: payload.portfolioMessageBlockTitle,
      portfolioMessageBlockText: payload.portfolioMessageBlockText,
    })
  }

  const submitInvestorPortfolioLink = () => {
    const formData = new FormData(refForm.current)
    const payload = Object.fromEntries(formData)

    return postInvestorByUserIdRequest(userId, {
      portfolioLink: payload.portfolioLink,
    })
  }

  const submitInvestorInvestmentCategories = () => {
    const formData = new FormData(refForm.current)
    const payload = Object.fromEntries(formData)

    return postInvestorByUserIdRequest(userId, {
      blockchainService: parseFloat(payload.blockchainService).toFixed(2),
      defi: parseFloat(payload.defi).toFixed(2),
      cefi: parseFloat(payload.cefi).toFixed(2),
      chain: parseFloat(payload.chain).toFixed(2),
      nft: parseFloat(payload.nft).toFixed(2),
      blockchainInfrastructure: parseFloat(payload.blockchainInfrastructure).toFixed(2),
      social: parseFloat(payload.social).toFixed(2),
      gamefi: parseFloat(payload.gamefi).toFixed(2),
      stablecoin: parseFloat(payload.stablecoin).toFixed(2),
      other: parseFloat(payload.other).toFixed(2),
      currency: parseFloat(payload.currency).toFixed(2),
      displayInvestmentCategoriesInPortfolio: payload.displayInvestmentCategoriesInPortfolio === 'on',
    })
  }

  const submitInvestorStockChart = () => {
    const formData = new FormData(refForm.current)
    const payload = Object.fromEntries(formData)

    return postInvestorByUserIdRequest(userId, {
      displayStocksChartInPortfolio: payload.displayStocksChartInPortfolio === 'on',
    })
  }

  const submitUserCredentials = () => {
    const formData = new FormData(refForm.current)
    const payload = Object.fromEntries(formData)

    return postUserRequest(userId, {
      username: payload.username,
      password: payload.password,
    })
  }

  const submitInvestorPersonalCabinetMessage = () => {
    const formData = new FormData(refForm.current)
    const payload = Object.fromEntries(formData)

    return postInvestorByUserIdRequest(userId, {
      personalCabinetMessage: payload.personalCabinetMessage,
    })
  }


  const fetchDetailsAndFillForm = async (investorId) => {
    refForm.current.reset()
    setInvestorLoading(true)
    setUserLoading(true)

    getUserRequest(investorId)
      .then((user) => {
        const { username, password } = user

        setUser(user)

        refForm.current['username'].value = username
        refForm.current['password'].value = password
      })
      .finally(() => {
        setUserLoading(false)
      })

    getInvestorByUserIdRequest(investorId)
      .then((investor) => {
        const {
          portfolioLink,
          initialInvestment,
          portfolioMessageBlockTitle,
          portfolioMessageBlockText,
          fullName,
          personalCabinetMessage,
          displayInvestmentCategoriesInPortfolio,
          displayStocksChartInPortfolio,
        } = investor

        setInvestor(investor)

        refForm.current['fullName'].value = fullName
        refForm.current['initialInvestment'].value = initialInvestment
        refForm.current['portfolioMessageBlockTitle'].value = portfolioMessageBlockTitle
        refForm.current['portfolioMessageBlockText'].value = portfolioMessageBlockText
        refForm.current['portfolioLink'].value = portfolioLink
        refForm.current['personalCabinetMessage'].value = personalCabinetMessage
        setDisplayInvestmentCategoriesInPortfolio(displayInvestmentCategoriesInPortfolio)
        setDisplayStocksChartInPortfolio(displayStocksChartInPortfolio)
      })
      .finally(() => {
        setInvestorLoading(false)
      })
  }

  // FETCH USER AND INVESTOR DETAILS
  useEffect(() => {
    if (!userId) return

    fetchDetailsAndFillForm(userId)
  }, [userId])

  // FETCH CHART DATA
  useEffect(() => {
    if (!investor?.portfolioLink) {
      setChartData(null)
      return
    }

    setChartDataLoading(true)

    scrapeLinkRequest(investor.portfolioLink)
      .then(({ chartData }) => {
        setChartData(chartData)
      })
      .finally(() => {
        setChartDataLoading(false)
      })
  }, [investor?.portfolioLink])

  const openRestoreDialog = () => {
    setShowRestoreDialog(true)
  }

  const closeRestoreDialog = () => {
    setShowRestoreDialog(false)
  }

  const restoreInvestor = () => {
    setRestoreLoading(true)

    postInvestorByUserIdRequest(userId, {
      banReason: '',
      banned: false
    })
      .then((investorUpdated) => {
        setInvestor(investorUpdated)
        closeRestoreDialog()
        toast.success(t('investorAccessWasRestoredSuccessfully'))
      })
      .finally(() => {
        setRestoreLoading(false)
      })
  }

  const openDeleteDialog = () => {
    setShowDeleteDialog(true)
  }

  const closeDeleteDialog = () => {
    setShowDeleteDialog(false)
  }

  const openBanDialog = () => {
    setShowBanDialog(true)
  }

  const closeBanDialog = () => {
    setShowBanDialog(false)
  }

  const deleteInvestor = async () => {
    setDeleteLoading(true)

    try {
      await deleteInvestorByUserIdRequest(userId)
      await deleteUserRequest(userId)

      onEdited()
      closeDeleteDialog()
      toast.success(t('investorWasDeletedSuccessfully'))
    } finally {
      setDeleteLoading(false)
    }
  }

  const banInvestor = () => {
    setBanLoading(true)

    const valid = banMessageRef.current.checkValidity()
    banMessageRef.current.reportValidity()
    if (!valid) {
      return
    }

    postInvestorByUserIdRequest(userId, {
      banReason,
      banned: true
    })
      .then((investorUpdated) => {
        setInvestor(investorUpdated)
        closeBanDialog()
        setBanReason('')
        toast.success(t('investorAccessWasRestrictedSuccessfully'))
      })
      .finally(() => {
        setBanLoading(false)
      })
  }

  return (
    <StyledRoot>
      <Typography variant="page-title">{investor?.fullName}</Typography>

      <StyledForm ref={refForm} onSubmit={handleSubmit}>
        <FormSection hasEditMode onSubmit={submitInvestorMainInfo} title={t('informationAboutInvestor')} gridTemplateColumns='1fr 1fr'>
          {
            ({ editMode, loading }) => (
              <>
                <FieldInvestorName disabled={investorLoading | !editMode || loading} loading={investorLoading}/>
                <FieldInitialInvestment disabled={investorLoading | !editMode || loading} loading={investorLoading}/>
                <FieldPortfolioMessageBlockTitle disabled={investorLoading | !editMode || loading} loading={investorLoading}/>
                <FieldPortfolioMessageBlockText disabled={investorLoading | !editMode || loading} loading={investorLoading}/>
              </>
            )
          }
        </FormSection>

        <FormSection hasEditMode onSubmit={submitUserCredentials} title={t('createAuthorizationData')}>
          {
            ({ editMode, loading }) => (
              <>
                <FieldLogin disabled={!editMode || loading} loading={userLoading}/>
                <FieldPublicPassword disabled={!editMode || loading} loading={userLoading}/>
                <CopyButton copyText={user?.username + ' : ' + user?.password}/>
              </>
            )
          }
        </FormSection>

        <FormSection hasEditMode onSubmit={submitInvestorPortfolioLink} title={t('linkOnPortfolio')}
                     gridTemplateColumns="2fr 1fr">
          {
            ({ editMode, loading }) => (
              <>
                <FieldPortfolioLink disabled={!editMode || loading} loading={investorLoading}/>
                <CopyButton copyText={investor?.portfolioLink}/>
              </>
            )
          }
        </FormSection>

        <FormSection hasEditMode onSubmit={submitInvestorInvestmentCategories} title={t('investmentCategories')}
                     gridTemplateColumns="1fr">
          {
            ({ editMode, loading }) => (
              <>
                <FieldDisplayInvestmentCategoriesInPortfolio
                  disabled={!editMode || loading}
                  checked={displayInvestmentCategoriesInPortfolio}
                  onChange={() => setDisplayInvestmentCategoriesInPortfolio(!displayInvestmentCategoriesInPortfolio)}
                />
                <FieldInvestmentCategories
                  disabled={!editMode || loading || !displayInvestmentCategoriesInPortfolio}
                  loading={investorLoading}
                  editMode={editMode}
                  data={investor}
                />
              </>
            )
          }
        </FormSection>

        <FormSection hasEditMode onSubmit={submitInvestorStockChart} title={t('portfolioChart')}
                     gridTemplateColumns="1fr">
          {
            ({ editMode, loading }) => (
              <>
                <FieldDisplayStocksChartInPortfolio
                  disabled={!editMode || loading}
                  checked={displayStocksChartInPortfolio}
                  onChange={() => setDisplayStocksChartInPortfolio(!displayStocksChartInPortfolio)}
                />
                {chartDataLoading || investorLoading ? <Spinner /> : <StocksLineChart
                  disabled={!editMode || loading || !displayStocksChartInPortfolio}
                  chartData={chartData}
                /> }
              </>
            )
          }
        </FormSection>

        <FormSection hasEditMode onSubmit={submitInvestorPersonalCabinetMessage}
                     title={t('investorPersonalCabinetMessage')}>
          {({ editMode, loading }) => (
            <FieldPersonalCabinetMessage disabled={investorLoading | !editMode || loading} />
          )}
        </FormSection>

        <HorizontalDivider size="big"/>

        <StyledActionsSection>
          <Button
            loading={investor?.banned ? restoreLoading : banLoading}
            type="button"
            variant="warning"
            onClick={investor?.banned ? openRestoreDialog : openBanDialog}
          >{t(investor?.banned ? 'restoreAccess' : 'restrictAccess')}</Button>
          <Button loading={deleteLoading} type="button" variant="danger"
                  onClick={openDeleteDialog}>{t('delete')}</Button>
        </StyledActionsSection>
      </StyledForm>

      <ConfirmationDialog
        shown={showBanDialog}
        cancelText={t('cancel')}
        confirmText={t('restrictAccess')}
        text={t('doYouWantToRestrictInvestor') + '?'}
        onConfirm={banInvestor}
        onCancel={closeBanDialog}
        onClose={closeBanDialog}
      >
        <Field label={t('enterMessageForInvestor')}>
          <TextArea
            ref={banMessageRef}
            required
            name="banReason"
            placeholder={t('enterMessageText')}
            value={banReason}
            onChange={(e) => setBanReason(e.target.value)}
          />
        </Field>
      </ConfirmationDialog>

      <ConfirmationDialog
        shown={showRestoreDialog}
        cancelText={t('cancel')}
        confirmText={t('restoreAccess')}
        text={t('doYouWantToRestoreAccess') + '?'}
        onConfirm={restoreInvestor}
        onCancel={closeRestoreDialog}
        onClose={closeRestoreDialog}
      />

      <ConfirmationDialog
        shown={showDeleteDialog}
        isDangerConfirm
        cancelText={t('cancel')}
        confirmText={t('delete')}
        text={t('doYouWantToDeleteInvestor') + '?'}
        onConfirm={deleteInvestor}
        onCancel={closeDeleteDialog}
        onClose={closeDeleteDialog}
      />
    </StyledRoot>
  )
}