import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Input } from './Input.component'
import { Field } from './Field.component'
import { IconButton } from './IconButton.component'
import { generatePassword } from '../utils'
import { useEffect, useRef, useState } from 'react'
import { RefreshIcon } from '../icons'
import { scrapeLinkRequest } from '../services/api/scrape-link.api'
import { InvestmentCategoriesDonutChart } from './InvestmentCategoriesDonutChart.component'
import { Switch } from './Switch.component'
import { TextArea } from './TextArea.component'


export const FieldInvestorName = ({ disabled, loading }) => {
  const { t } = useTranslation()

  return (
    <Field disabled={disabled} label={t('investorName')}>
      <Input
        required
        disabled={disabled}
        type="text"
        name="fullName"
        placeholder={t('enterName')}
        loading={loading}
      />
    </Field>
  )
}

export const FieldInitialInvestment = ({ disabled, loading }) => {
  const { t } = useTranslation()

  return (
    <Field disabled={disabled} label={t('investmentUSD')}>
      <Input
        required
        disabled={disabled}
        type="text"
        name="initialInvestment"
        placeholder={t('enterAmount')}
        loading={loading}
      />
    </Field>
  )
}

export const FieldPortfolioMessageBlockTitle = ({ disabled, loading }) => {
  const { t } = useTranslation()

  return (
    <Field disabled={disabled} label={t('portfolioMessageBlockTitle')}>
      <Input
        disabled={disabled}
        type="text"
        name="portfolioMessageBlockTitle"
        placeholder={t('enterTitle')}
        loading={loading}
      />
    </Field>
  )
}

export const FieldPortfolioMessageBlockText = ({ disabled, loading }) => {
  const { t } = useTranslation()

  return (
    <Field disabled={disabled} label={t('portfolioMessageBlockText')}>
      <Input
        disabled={disabled}
        type="text"
        name="portfolioMessageBlockText"
        placeholder={t('enterMessageText')}
        loading={loading}
      />
    </Field>
  )
}


export const FieldLogin = ({ disabled, loading }) => {
  const { t } = useTranslation()

  return (
    <Field disabled={disabled} label={t('login')}>
      <Input
        type="text"
        name="username"
        placeholder={t('enterLogin')}
        required
        loading={loading}
        disabled={disabled}
      />
    </Field>
  )
}

export const FieldPublicPassword = ({ disabled, loading }) => {
  const { t } = useTranslation()
  const rootRef = useRef(null)

  const generateAndSetPassword = () => {
    rootRef.current.value = generatePassword()
  }

  useEffect(() => {
    rootRef.current.regeneratePassword = generateAndSetPassword
  }, [])

  return (
    <Field disabled={disabled} label={t('password')}>
      <Input
        ref={rootRef}
        loading={loading}
        disabled={disabled}
        slotAfter={<IconButton disabled={disabled} type="button" onClick={generateAndSetPassword}>
          <RefreshIcon/>
        </IconButton>}
        type="text"
        name="password"
        placeholder={t('enterPassword')}
        required
      />
    </Field>
  )
}


const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`

export const FieldPortfolioLink = ({ loading, disabled, onPortfolioScraped, onPortfolioScrapeLoadingChange }) => {
  const { t } = useTranslation()

  const [localValue, setLocalValue] = useState('')
  const [debouncingPortfolioScrape, setDebouncingPortfolioScrape] = useState(false)
  const [portfolioScrapeLoading, setPortfolioScrapeLoading] = useState(false)
  const [valid, setValid] = useState(false)

  useEffect(() => {
    if (!localValue) return

    setDebouncingPortfolioScrape(true)

    const timeout = setTimeout(() => {
      setDebouncingPortfolioScrape(false)

      setPortfolioScrapeLoading(true)
      onPortfolioScrapeLoadingChange?.(true)

      scrapeLinkRequest(localValue)
        .then((portfolioScrape) => {
          setValid(portfolioScrape.scrapeDataValid)
          onPortfolioScraped?.(portfolioScrape)
        })
        .finally(() => {
          setPortfolioScrapeLoading(false)
          onPortfolioScrapeLoadingChange?.(false)
        })
    }, 500)

    return () => {
      clearTimeout(timeout)
      setDebouncingPortfolioScrape(false)
    }
  }, [localValue])

  const handleChange = (e) => {
    setLocalValue(e.target.value)
  }

  return (
    <Field disabled={disabled} label={t('pasteLinkFromService')}>
      <Input
        type="text"
        name="portfolioLink"
        required
        placeholder="https://dropstab.com/....."
        onChange={handleChange}
        disabled={disabled}
        loading={loading || debouncingPortfolioScrape || portfolioScrapeLoading}
        slotAfter={localValue ? valid ? (
          <StyledIconWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20px" fill="green" viewBox="0 0 122.88 102.97">
              <path className="cls-1"
                    d="M4.82,69.68c-14.89-16,8-39.87,24.52-24.76,5.83,5.32,12.22,11,18.11,16.27L92.81,5.46c15.79-16.33,40.72,7.65,25.13,24.07l-57,68A17.49,17.49,0,0,1,48.26,103a16.94,16.94,0,0,1-11.58-4.39c-9.74-9.1-21.74-20.32-31.86-28.9Z"/>
            </svg>
          </StyledIconWrapper>
        ) : (
          <StyledIconWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20px" fill="red" viewBox="0 0 122.88 122.88">
              <path className="cls-1"
                    d="M6,6H6a20.53,20.53,0,0,1,29,0l26.5,26.49L87.93,6a20.54,20.54,0,0,1,29,0h0a20.53,20.53,0,0,1,0,29L90.41,61.44,116.9,87.93a20.54,20.54,0,0,1,0,29h0a20.54,20.54,0,0,1-29,0L61.44,90.41,35,116.9a20.54,20.54,0,0,1-29,0H6a20.54,20.54,0,0,1,0-29L32.47,61.44,6,34.94A20.53,20.53,0,0,1,6,6Z"/>
            </svg>
          </StyledIconWrapper>
        ) : null}
      />
    </Field>
  )
}

export const FieldPersonalCabinetMessage = ({ disabled }) => {
  const { t } = useTranslation()

  return (
    <Field disabled={disabled} label={t('enterMessage')}>
      <TextArea disabled={disabled} name="personalCabinetMessage" placeholder={t('enterMessageForInvestor')} />
    </Field>
  )
}

export const FieldDisplayInvestmentCategoriesInPortfolio = ({ disabled, defaultChecked, checked, onChange }) => {
  const { t } = useTranslation()

  return (
    <Switch defaultChecked={defaultChecked} disabled={disabled} label={t('displayInPortfolio')}
            name="displayInvestmentCategoriesInPortfolio" checked={checked} onChange={onChange}/>
  )
}

export const FieldDisplayStocksChartInPortfolio = ({ disabled, defaultChecked, checked, onChange }) => {
  const { t } = useTranslation()

  return (
    <Switch defaultChecked={defaultChecked} disabled={disabled} name="displayStocksChartInPortfolio"
            label={t('displayInPortfolio')} checked={checked} onChange={onChange}/>
  )
}

export const FieldInvestmentCategories = ({ editMode, disabled, data }) => {
  return <InvestmentCategoriesDonutChart disabled={disabled} editMode={editMode} data={data}/>
}