import { makeRequest } from './make-request.api'


const INVESTOR_API_PATH = '/investor'

export const getAllInvestorsRequest = () => {
  return makeRequest(INVESTOR_API_PATH)
}


export const getInvestorByUserIdRequest = (userId) => {
  return makeRequest(INVESTOR_API_PATH + '/' + userId)
}

export const postInvestorByUserIdRequest = (userId, payload) => {
  return makeRequest(INVESTOR_API_PATH + '/' + userId, {
    method: 'POST',
    body: payload,
  })
}

export const putInvestorByUserIdRequest = (userId, payload) => {
  return makeRequest(INVESTOR_API_PATH + '/' + userId, {
    method: 'PUT',
    body: payload,
  })
}

export const deleteInvestorByUserIdRequest = (userId) => {
  return makeRequest(INVESTOR_API_PATH + '/' + userId, {
    method: 'DELETE'
  })
}

export const swapInvestorsOrderRequest = (investorId1, investorId2) => {
  return makeRequest(INVESTOR_API_PATH + '/swap-order', {
    method: 'POST',
    body: {
      id1: investorId1,
      id2: investorId2,
    },
  })
}