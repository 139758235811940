import styled from 'styled-components'

import { Typography } from './Typography.component'
import { classNames } from '../utils'


const StyledRoot = styled.label`
  display: grid;
  grid-row-gap: 10px;
  
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`

export const Field = ({ disabled, isLabelBold, label, children }) => {
  return (
    <StyledRoot className={classNames({ disabled, })}>
      <Typography variant={isLabelBold ? 'label-bold' : 'label'}>{label}</Typography>
      {children}
    </StyledRoot>
  )
}