import styled, { createGlobalStyle } from 'styled-components'

import { useLayoutEffect, useRef } from 'react'
import {
  useFocusControl, usePutBackScrollPosition
} from '../hooks'
import { CloseButton } from './CloseButton.component'


const StyledRoot = styled.div`
  z-index: ${({ theme }) => theme.zIndex.modal};
  position: fixed;
  top: var(--top);
  width: calc(100% - var(--left));
  height: calc(100% - var(--top));
  overflow-x: hidden;
  overflow-y: auto;

  box-sizing: border-box;
  padding: 40px;

  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  transition: 0.35s;
  outline: none;

  left: -100%;
  opacity: 0.5;
  pointer-events: none;
  
  &.shown {
    left: var(--left);
    opacity: 1;
    pointer-events: all;
  }
  
  &:not(.shown) {
    & > * {
      display: none;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    --left: 0 !important;
    padding: 20px;
    width: 100% !important;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.textPrimary};
    border-radius: 5px;
  }
`

const StyledCloseButtonWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 40px;
`

const GlobalStyle = createGlobalStyle`
    // Hide Navigation Bar on mobile to not overlap with the Modal content
    @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
      nav {
        transform: translateX(-100%);
      }
    }
`

export const Modal = ({ noCloseButton, shown, onClose, children }) => {
  const rootRef = useRef()

  useFocusControl(rootRef, shown, true)
  usePutBackScrollPosition(shown)

  // Lock body scroll when the modal is shown
  // Set the top and left position of the modal
  useLayoutEffect(() => {
    if (shown) {
      const rootElement = rootRef.current
      const headerElement = document.querySelector('header')

      if (headerElement) {
        rootElement.style.setProperty('--top', `${headerElement.offsetHeight}px`)
      }

      const navElement = document.querySelector('nav')

      if (navElement) {
        rootElement.style.setProperty('--left', `${navElement.offsetWidth}px`)
      }

      const initialOverflow = document.body.style.overflow
      document.body.style.overflow = 'hidden'

      return () => {
        document.body.style.overflow = initialOverflow
      }
    }
  }, [shown])

  const onKeyDown = (event) => {
    if (event.key === 'Escape') {
      onClose()
      event.stopPropagation()
    }
  }

  return (
    <StyledRoot ref={rootRef} className={shown ? 'shown' : ''} tabIndex={0} onKeyDown={onKeyDown}>
        {shown && <GlobalStyle />}
        {children}

        {!noCloseButton && (
          <StyledCloseButtonWrapper>
            <CloseButton onClick={onClose} />
          </StyledCloseButtonWrapper>
        )}
      </StyledRoot>
  )
}