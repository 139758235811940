import styled from 'styled-components'

import { Dialog } from './Dialog.component'
import { Typography } from './Typography.component'
import { Button } from './Button.component'


const IconSuccess = () => (
  <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1_1026)">
      <path d="M10 72C10 37.7583 37.7583 10 72 10C106.242 10 134 37.7583 134 72C134 106.242 106.242 134 72 134C37.7583 134 10 106.242 10 72Z" fill="#1FDE00"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.1668 71.9997C36.1668 91.7439 52.256 107.833 72.0002 107.833C91.7443 107.833 107.833 91.7439 107.833 71.9997C107.833 52.2555 91.7443 36.1664 72.0002 36.1664C52.256 36.1664 36.1668 52.2555 36.1668 71.9997ZM68.811 84.0755C68.3093 84.5772 67.6285 84.8638 66.9118 84.8638C66.1952 84.8638 65.5143 84.5772 65.0127 84.0755L54.8718 73.9347C53.8327 72.8955 53.8327 71.1755 54.8718 70.1363C55.911 69.0972 57.631 69.0972 58.6702 70.1363L66.9118 78.378L85.3302 59.9597C86.3693 58.9205 88.0893 58.9205 89.1285 59.9597C90.1677 60.9989 90.1677 62.683 89.1285 63.758L68.811 84.0755Z" fill="white"/>
    <defs>
      <filter id="filter0_d_1_1026" x="0" y="0" width="144" height="144" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_1026"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_1026" result="shape"/>
      </filter>
    </defs>
  </svg>
)

const StyledRoot = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  padding: 75px 0;
`

const StyledIconWrapper = styled.div`
  margin: 30px 0;
`

export const InfoDialog = ({
                             text,
                             iconSuccess,
                             icon,
                             confirmText,

                             shown,
                             onConfirm,
                             onClose,

                             children
                           }) => {
  return (
    <Dialog shown={shown} onClose={onClose}>
      <StyledRoot>
        <Typography variant="paper-title">{text}</Typography>
        {(icon || iconSuccess) && <StyledIconWrapper>{icon || <IconSuccess />}</StyledIconWrapper>}
        {children}
        <Button style={{ width: '100%' }} variant="info" onClick={onConfirm}>{confirmText}</Button>
      </StyledRoot>
    </Dialog>
  )
}