import styled from 'styled-components'
import { useEffect, useRef } from 'react'


const ANIMATION_DURATION = 4

const StyledRoot = styled.div`
  position: relative;
  
  overflow: hidden;
  min-width: 0;
  background: linear-gradient(90deg, #000, #fff, #000);
  background-repeat: no-repeat;
  background-size: 1000%;
  animation: animate ${ANIMATION_DURATION}s forwards;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);

  @keyframes animate {
    from {
      background-position: 130% 50%;
    }
    to {
      background-position: 30% 50%;
    }
  }
`

export const ShiningWrapper = ({ children }) => {
  const rootRef = useRef()

  useEffect(() => {
    const timerId = setTimeout(() => {
      rootRef.current.style.animation = 'none'
      rootRef.current.style['background'] = 'unset'
      rootRef.current.style['-webkit-background-clip'] = 'unset'
      rootRef.current.style['-webkit-text-fill-color'] = 'unset'
    }, (ANIMATION_DURATION * 1000) / 2)

    return () => {
      clearTimeout(timerId)
    }
  }, [])

  return (
    <StyledRoot ref={rootRef}>
      {children}
    </StyledRoot>
  )
}