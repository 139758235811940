import { useTranslation } from 'react-i18next'

import { Button } from './Button.component'
import { toast } from 'react-toastify'


export const CopyButton = ({ copyText }) => {
  const { t } = useTranslation()

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(copyText)
    toast.success(t('copiedToClipboard'))
  }

  return (
    <Button type="button" variant="info" onClick={copyToClipboard}>
      {t('copy')}
    </Button>
  )
}