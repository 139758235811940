import { useRef } from 'react'
import styled from 'styled-components'

import { useMouseMove3dPerspective } from '../hooks'


const StyledRoot = styled.button`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.menu};
  
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
  
  background-color: transparent;
  color: ${({ theme }) => theme.color.textPrimary};
  outline: none;
  
  cursor: pointer;
  transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis));
  transition: 0.2s;
  
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.textPrimaryHover};
    }
  }
  
  &:active {
    transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(0.97);
  }
  
  &.active {
    background-color: ${({ theme }) => theme.color.textPrimaryHover};

    // Fake font-weight bold to avoid moving the content
    text-shadow:
            -0.2px 0 currentColor, 0 0.2px currentColor, 0.2px 0 currentColor, 0 -0.2px currentColor,
              -0.2px -0.2px currentColor, 0.2px 0.2px currentColor, -0.2px 0.2px currentColor, 0.2px -0.2px currentColor;
    // ================================================
  }
  
  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    padding: 14px 10px;
  }
`

const StyledIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 24px;
  margin-right: 10px;
`

export const MenuItem = ({ active, icon, children, ...restProps }) => {
  const rootRef = useRef()

  const tabIndexProp = active ? { 'data-focusable': 'true' } : {}

  useMouseMove3dPerspective(rootRef)

  return (
    <StyledRoot ref={rootRef} {...restProps} className={active ? 'active' : ''} {...tabIndexProp}>
      {icon ? <StyledIcon>{icon}</StyledIcon> : ''}
      {children}
    </StyledRoot>
  )
}