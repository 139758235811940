import { useNavigate } from 'react-router-dom'

import { AdminLayout } from '../layouts'
import { AddInvestorForm } from '../components'
import { ROUTE } from '../routing'
import { useScrollToTop } from '../hooks/useScrollToTop'


export const AddInvestorPage = () => {
  const navigate = useNavigate()

  const handleAddSuccess = () => {
    navigate(ROUTE.investors)
  }

  useScrollToTop()

  return (
    <AdminLayout parentRoute={ROUTE.investors}>
      <AddInvestorForm onAdded={handleAddSuccess} />
    </AdminLayout>
  )
}
