export const LogOutIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill="white"/>
    <path
      d="M11.2252 16.25C11.2095 16.2083 11.2041 16.1729 11.2094 16.1443C11.2147 16.1158 11.1965 16.0922 11.1548 16.0741C11.1131 16.0559 11.0922 16.0415 11.0922 16.0312C11.0922 16.0208 11.0623 16.0129 11.0024 16.0078C10.9424 16.0026 10.9125 15.9999 10.9125 15.9999H8.47507C8.13132 15.9999 7.83702 15.8776 7.5922 15.6327C7.34743 15.3879 7.22506 15.0936 7.22506 14.7499V9.24986C7.22506 8.90611 7.3474 8.61193 7.5922 8.36713C7.83702 8.1223 8.13132 7.99985 8.47507 7.99985H10.9751C11.0428 7.99985 11.096 7.98305 11.1352 7.94912C11.1742 7.91527 11.2002 7.86445 11.2133 7.79675C11.2263 7.72906 11.2341 7.66793 11.2368 7.61322C11.2393 7.55858 11.2381 7.48951 11.2328 7.40619C11.2277 7.32289 11.2251 7.27082 11.2251 7.24989C11.2251 7.18219 11.2003 7.12371 11.1508 7.07413C11.1013 7.02476 11.0428 7 10.9751 7H8.47507C7.85533 7 7.32535 7.22006 6.8852 7.66015C6.44516 8.10025 6.2251 8.63015 6.2251 9.24994V14.7499C6.2251 15.3697 6.44516 15.8997 6.8852 16.3396C7.32535 16.7799 7.85533 17 8.47507 17H10.9752C11.0429 17 11.0962 16.983 11.1353 16.9493C11.1743 16.9154 11.2003 16.8646 11.2134 16.7968C11.2264 16.7292 11.2343 16.6678 11.2369 16.6133C11.2394 16.5586 11.2382 16.4897 11.2329 16.4063C11.2277 16.3229 11.2252 16.2709 11.2252 16.25Z"
      fill="black"/>
    <path
      d="M18.328 11.6483L14.078 7.39818C13.9791 7.29934 13.8619 7.24976 13.7264 7.24976C13.591 7.24976 13.4738 7.29934 13.3748 7.39818C13.2758 7.49715 13.2264 7.61446 13.2264 7.7498V9.99983H9.72637C9.59086 9.99983 9.47372 10.0494 9.37474 10.1482C9.27576 10.2472 9.22632 10.3645 9.22632 10.4999V13.4999C9.22632 13.6353 9.27576 13.7526 9.37474 13.8515C9.47382 13.9503 9.59094 14 9.72637 14H13.2264V16.25C13.2264 16.3853 13.2758 16.5026 13.3748 16.6015C13.4738 16.7005 13.591 16.7501 13.7265 16.7501C13.8619 16.7501 13.9791 16.7005 14.078 16.6015L18.328 12.3516C18.427 12.2525 18.4764 12.1355 18.4764 12C18.4764 11.8646 18.427 11.7473 18.328 11.6483Z"
      fill="black"/>
  </svg>
)