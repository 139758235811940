import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Typography } from './Typography.component'
import { Button } from './Button.component'
import { putInvestorByUserIdRequest, putUserRequest } from '../services'
import { InfoDialog } from './InfoDialog.component'
import { FormSection } from './FormSection.component'
import {
  FieldDisplayInvestmentCategoriesInPortfolio,
  FieldDisplayStocksChartInPortfolio,
  FieldInitialInvestment,
  FieldInvestmentCategories,
  FieldInvestorName,
  FieldLogin,
  FieldPersonalCabinetMessage,
  FieldPortfolioLink,
  FieldPortfolioMessageBlockText,
  FieldPortfolioMessageBlockTitle,
  FieldPublicPassword
} from './Fields.component'
import { Spinner } from './Spinner.component'
import { StocksLineChart } from './StocksLinearChart.component'


const StyledRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
`

const StyledForm = styled.form`
  display: grid;
  grid-row-gap: 30px;
`

const DEFAULT_INVESTMENT_CATEGORIES = {
  blockchainService: 0,
  defi: 0,
  cefi: 0,
  chain: 0,
  nft: 0,
  blockchainInfrastructure: 0,
  social: 0,
  gamefi: 0,
  stablecoin: 0,
  other: 0,
  currency: 0,
}

export const AddInvestorForm = ({ onAdded }) => {
  const { t } = useTranslation()

  const refForm = useRef()

  const [isSuccessDialogShown, setIsSuccessDialogShown] = useState(false)

  const [portfolioScrapeLoading, setPortfolioScrapeLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [chartData, setChartData] = useState(null)
  const [displayInvestmentCategoriesInPortfolio, setDisplayInvestmentCategoriesInPortfolio] = useState(false)
  const [displayStocksChartInPortfolio, setDisplayStocksChartInPortfolio] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()

    const formData = new FormData(event.target)
    const payload = Object.fromEntries(formData)

    try {
      setSubmitLoading(true)

      const user = await putUserRequest({
        username: payload.username,
        password: payload.password,
      })

      await putInvestorByUserIdRequest(user.id, {
        fullName: payload.fullName,
        portfolioLink: payload.portfolioLink,
        initialInvestment: payload.initialInvestment,
        portfolioMessageBlockTitle: payload.portfolioMessageBlockTitle,
        portfolioMessageBlockText: payload.portfolioMessageBlockText,

        personalCabinetMessage: payload.personalCabinetMessage,

        displayInvestmentCategoriesInPortfolio: payload.displayInvestmentCategoriesInPortfolio === 'on',
        displayStocksChartInPortfolio: payload.displayStocksChartInPortfolio === 'on',

        blockchainService: payload.blockchainService,
        defi: payload.defi,
        cefi: payload.cefi,
        chain: payload.chain,
        nft: payload.nft,
        blockchainInfrastructure: payload.blockchainInfrastructure,
        social: payload.social,
        gamefi: payload.gamefi,
        stablecoin: payload.stablecoin,
        other: payload.other,
        currency: payload.currency,
      })

      setIsSuccessDialogShown(true)
    } catch (error) {
      // no-op
    } finally {
      setSubmitLoading(false)
    }
  }

  const fillFormWithPortfolioScrape = async (portfolioScrape) => {
    const { currentBalance, username, scrapeDataValid, chartData } = portfolioScrape

    setChartData(chartData)

    if (!scrapeDataValid) {
      return
    }

    if (!refForm.current['initialInvestment'].value) {
      refForm.current['initialInvestment'].value = currentBalance
    }
    if (!refForm.current['fullName'].value) {
      refForm.current['fullName'].value = username
    }
    if (!refForm.current['username'].value) {
      refForm.current['username'].value = username
    }
    if (!refForm.current['password'].value) {
      refForm.current['password'].regeneratePassword()
    }
  }

  const handleCloseSuccessInfoModal = () => {
    onAdded()
    setIsSuccessDialogShown(false)
    refForm.current.reset()
  }

  return (
    <StyledRoot>
      <Typography variant="page-title">{t('addingInvestor')}</Typography>

      <StyledForm ref={refForm} onSubmit={handleSubmit}>

        <FormSection title={t('informationAboutInvestor')} gridTemplateColumns="1fr 1fr">
          <FieldInvestorName disabled={portfolioScrapeLoading} loading={portfolioScrapeLoading}/>
          <FieldInitialInvestment disabled={portfolioScrapeLoading} loading={portfolioScrapeLoading}/>
          <FieldPortfolioMessageBlockTitle/>
          <FieldPortfolioMessageBlockText/>
        </FormSection>

        <FormSection title={t('createAuthorizationData')}>
          <FieldLogin loading={portfolioScrapeLoading}/>
          <FieldPublicPassword loading={portfolioScrapeLoading}/>
        </FormSection>

        <FormSection title={t('linkOnPortfolio')}>
          <FieldPortfolioLink onPortfolioScraped={fillFormWithPortfolioScrape}
                              onPortfolioScrapeLoadingChange={setPortfolioScrapeLoading}/>
        </FormSection>

        <FormSection title={t('investmentCategories')} gridTemplateColumns="1fr">
          <FieldDisplayInvestmentCategoriesInPortfolio
            checked={displayInvestmentCategoriesInPortfolio}
            onChange={() => setDisplayInvestmentCategoriesInPortfolio(!displayInvestmentCategoriesInPortfolio)}
          />
          <FieldInvestmentCategories
            disabled={!displayInvestmentCategoriesInPortfolio}
            editMode={true}
            data={DEFAULT_INVESTMENT_CATEGORIES}
          />
        </FormSection>

        <FormSection title={t('portfolioChart')} gridTemplateColumns="1fr">
          <FieldDisplayStocksChartInPortfolio
            checked={displayStocksChartInPortfolio}
            onChange={() => setDisplayStocksChartInPortfolio(!displayStocksChartInPortfolio)}
          />
          {portfolioScrapeLoading ? <Spinner/> : <StocksLineChart
            disabled={!displayStocksChartInPortfolio}
            chartData={chartData}
          />}
        </FormSection>

        <FormSection title={t('investorPersonalCabinetMessage')}>
          <FieldPersonalCabinetMessage/>
        </FormSection>

        <Button loading={submitLoading} type="submit" variant="success">{t('addNewInvestor')}</Button>
      </StyledForm>

      <InfoDialog
        shown={isSuccessDialogShown}
        iconSuccess
        text={t('investorSuccessfullyAdded')}
        confirmText={t('investorsList')}
        onConfirm={handleCloseSuccessInfoModal}
        onClose={handleCloseSuccessInfoModal}
      />
    </StyledRoot>
  )
}