import styled from 'styled-components'
import { forwardRef } from 'react'


const StyledRoot = styled.div`
  padding: 20px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
}
`

export const Paper = forwardRef(({ as, children, ...restProps }, ref) => {
  return (
    <StyledRoot ref={ref} as={as} {...restProps}>
      {children}
    </StyledRoot>
  )
})