import { useContext } from 'react'
import { ThemeProvider } from 'styled-components'

import { AuthorizationContext } from './contexts'
import { ADMIN_THEME, INVESTOR_THEME } from './theme'


export const ContextWrapper = ({ children }) => {
  const { isAdmin } = useContext(AuthorizationContext)

  const theme = isAdmin ? ADMIN_THEME : INVESTOR_THEME

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}