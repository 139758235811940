import { forwardRef, useState, useRef } from 'react'
import styled from 'styled-components'

import { Spinner } from './Spinner.component'
import { classNames } from '../utils'
import { useMouseMove3dPerspective } from '../hooks'


const StyledRoot = styled.div`
  box-sizing: border-box;
  display: grid;
  align-content: center;
  align-items: center;
  justify-items: center;
  grid-column-gap: 10px;
  padding: 0 20px;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadius.formElement};

  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  color: ${({ theme }) => theme.color.textPrimary};
  transition: 0.2s;
  transform: perspective(calc(var(--perspective) * 3)) rotateX(var(--xAxis)) rotateY(var(--yAxis));

  &.focused {
    border-color: ${({ theme }) => theme.color.textSecondary};
    transform: scale(1.02);
  }

  &.disabled {
    color: ${({ theme }) => theme.color.textSecondary};
    opacity: 0.8;
    pointer-events: none;
  }
`

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 10px;
  border: none;

  font-family: 'Montserrat', sans-serif;
  font-size: 16px;

  background: none;
  color: ${({ theme }) => theme.color.textPrimary};
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.color.textSecondary};
  }

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    padding: 15px 10px;
  }
`

export const Input = forwardRef(({ loading, as, slotBefore, slotAfter, ...restProps }, ref) => {
  const rootRef = useRef()
  useMouseMove3dPerspective(rootRef)
  const [focused, setFocused] = useState(false)

  return (
    <StyledRoot
      ref={rootRef}
      className={classNames({
        focused,
        disabled: restProps.disabled
      })}
      style={{ gridTemplateColumns: (slotBefore ? 'auto ' : '') + '1fr' + ((slotAfter || loading) ? ' auto' : '') }}
    >
      {slotBefore}
      <StyledInput ref={ref} {...restProps} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}/>
      {loading ? <Spinner/> : slotAfter}
    </StyledRoot>
  )
})