import styled from 'styled-components'

import { Typography } from './Typography.component'
import { Paper } from './Paper.component'


const StyledRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2px;
  
  text-align: center;

  &.big {
    grid-row-gap: 26px;
  }
`

/**
 *
 * @param {'small' | 'big'} size
 * @param label
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
export const DetailsItem = ({ size, caption, value, children }) => {
  const isBig = size === 'big'
  const valueComponent = <Typography variant={isBig ? 'big-value' : 'small-value-bold'}>{children ?? value}</Typography>

  return (
    <StyledRoot as={Paper} className={size}>
      {!isBig && valueComponent}

      <Typography variant={isBig ? 'big-caption' : 'small-caption'}>{caption}</Typography>

      {isBig && valueComponent}
    </StyledRoot>
  )
}