import { createGlobalStyle } from 'styled-components'

import { Route, Routes } from 'react-router-dom'
import { RoutePolicy } from './components'
import {
  AddInvestorPage,
  DashboardPage,
  EditInvestorPage,
  InvestorsPage,
  LandingPage,
  LoginPage,
  NotFoundPage,
  PortfolioPage,
  SettingsPage,
  WebsitePage
} from './pages'
import { ROUTE } from './routing'
import { ContextWrapper } from './ContextWrapper'
import { BannedPage } from './pages/Banned.page'


const GlobalStyle = createGlobalStyle`
  // Reset CSS
  html, body {
    margin: 0;
    padding: 0;
  }

  // Colors, fonts and scrollbar
  html {
    font-family: 'Montserrat', sans-serif;
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    color: ${({ theme }) => theme.color.textPrimary};

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.color.backgroundPrimary};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.textPrimary};
      border-radius: 5px;
    }
  }
  
  /**
   * @license Highcharts
   *
   * (c) 2009-2022 Torstein Honsi
   *
   * License: www.highcharts.com/license
   */

  :root,
  .highcharts-light {
    /* Colors for data series and points */
    --highcharts-color-0: #2caffe;
    --highcharts-color-1: #544fc5;
    --highcharts-color-2: #00e272;
    --highcharts-color-3: #fe6a35;
    --highcharts-color-4: #6b8abc;
    --highcharts-color-5: #d568fb;
    --highcharts-color-6: #2ee0ca;
    --highcharts-color-7: #fa4b42;
    --highcharts-color-8: #feb56a;
    --highcharts-color-9: #91e8e1;

    /* Chart background, point stroke for markers and columns etc */
    --highcharts-background-color: #ffffff;

    /*
    Neutral colors, grayscale by default. The default colors are defined by
    mixing the background-color with neutral, with a weight corresponding to
    the number in the name.

    https://www.highcharts.com/samples/highcharts/css/palette-helper
    */

    /* Strong text. */
    --highcharts-neutral-color-100: #000000;

    /* Main text, axis labels and some strokes. */
    --highcharts-neutral-color-80: #333333;

    /* Axis title, connector fallback. */
    --highcharts-neutral-color-60: #666666;

    /* Credits text, export menu stroke. */
    --highcharts-neutral-color-40: #999999;

    /* Disabled texts, button strokes, crosshair etc. */
    --highcharts-neutral-color-20: #cccccc;

    /* Grid lines etc. */
    --highcharts-neutral-color-10: #e6e6e6;

    /* Minor grid lines etc. */
    --highcharts-neutral-color-5: #f2f2f2;

    /* Tooltip backgroud, button fills, map null points. */
    --highcharts-neutral-color-3: #f7f7f7;

    /*
    Highlights, shades of blue by default
    */

    /* Drilldown clickable labels, color axis max color. */
    --highcharts-highlight-color-100: #0022ff;

    /* Selection marker, menu hover, button hover, chart border, navigator
    series. */
    --highcharts-highlight-color-80: #334eff;

    /* Navigator mask fill. */
    --highcharts-highlight-color-60: #667aff;

    /* Ticks and axis line. */
    --highcharts-highlight-color-20: #ccd3ff;

    /* Pressed button, color axis min color. */
    --highcharts-highlight-color-10: #e6e9ff;

    /* Indicators */
    --highcharts-positive-color: #06b535;
    --highcharts-negative-color: #f21313;
  }
`

export const App = () => {
  return (
    <ContextWrapper>
      <GlobalStyle/>
      <Routes>
        {/* COMMON */}
        <Route index element={<LandingPage/>}/>
        <Route path={ROUTE.login} element={<RoutePolicy onlyNonAuth><LoginPage/></RoutePolicy>}/>
        <Route path={ROUTE.banned} element={<RoutePolicy onlyAuth onlyInvestor><BannedPage/></RoutePolicy>}/>

        {/* INVESTOR */}
        <Route path={ROUTE.portfolio}
               element={<RoutePolicy onlyAuth onlyInvestor onlyNotBanned><PortfolioPage/></RoutePolicy>}/>

        {/* ADMIN */}
        <Route path={ROUTE.dashboard} element={<RoutePolicy onlyAuth onlyAdmin><DashboardPage/></RoutePolicy>}/>
        <Route path={ROUTE.investors} element={<RoutePolicy onlyAuth onlyAdmin><InvestorsPage/></RoutePolicy>}/>
        <Route path={ROUTE.website} element={<RoutePolicy onlyAuth onlyAdmin><WebsitePage/></RoutePolicy>}/>
        <Route path={ROUTE.settings} element={<RoutePolicy onlyAuth onlyAdmin><SettingsPage/></RoutePolicy>}/>

        <Route path={ROUTE.addInvestor} element={<RoutePolicy onlyAuth onlyAdmin><AddInvestorPage/></RoutePolicy>}/>
        <Route path={ROUTE.editInvestor + '/:userId'} element={<RoutePolicy onlyAuth onlyAdmin><EditInvestorPage/></RoutePolicy>}/>

        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
    </ContextWrapper>
  )
}