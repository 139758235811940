import React, { useRef } from 'react'
import styled from 'styled-components';
import { Typography } from './Typography.component'
import { classNames } from '../utils'
import { useMouseMove3dPerspective } from '../hooks'


const SwitchWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const SwitchSlider = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  ${SwitchInput}:checked + & {
    background-color: ${({ theme }) => theme.color.success};
  }

  ${SwitchInput}:checked + &:before {
    transform: translateX(26px);
  }
`;

const StyledRoot = styled.label`
  justify-self: start;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  align-items: center;

  cursor: pointer;
  transition: 0.2s;
  user-select: none;
  
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:not(.disabled) {
    transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis));

    &:active {
      transform: scale(0.96);
    }

    &:focus {
      transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(0.99);
    }
  }
`;

export const Switch = ({ label, ...restProps }) => {
  const rootRef = useRef();
  useMouseMove3dPerspective(rootRef);

  return (
    <StyledRoot ref={rootRef} className={classNames({ disabled: restProps.disabled })}>
      <Typography variant="label">{label}</Typography>

      <SwitchWrapper>
        <SwitchInput type="checkbox" {...restProps} />
        <SwitchSlider />
      </SwitchWrapper>
    </StyledRoot>
  );
};
