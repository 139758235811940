import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const StyledRoot = styled.button`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 30px;
  align-items: center;
  justify-items: flex-start;
  justify-content: flex-start;
  padding: 15px 20px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  margin: -20px -20px 20px;
  width: calc(100% + 40px);

  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;

  background: none;
  color: ${({ theme }) => theme.color.textPrimary};
  
  cursor: pointer;
  
  transition: 0.2s;

  @media (hover: hover) {
    &:hover {
      filter: brightness(0.8);
    }
  }

  &:active {
    transform: scale(0.98);
  }

  svg {
    width: 1em;
    fill: currentColor;
  }
  
  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    font-size: 16px;
  }
`

export const ButtonBack = ({ routeBack }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateBack = () => {
    navigate(routeBack)
  }

  return (
    <StyledRoot onClick={navigateBack}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
           x="0px" y="0px" viewBox="0 0 66.91 122.88">
        <g>
          <path
            d="M64.96,111.2c2.65,2.73,2.59,7.08-0.13,9.73c-2.73,2.65-7.08,2.59-9.73-0.14L1.97,66.01l4.93-4.8l-4.95,4.8 c-2.65-2.74-2.59-7.1,0.15-9.76c0.08-0.08,0.16-0.15,0.24-0.22L55.1,2.09c2.65-2.73,7-2.79,9.73-0.14 c2.73,2.65,2.78,7.01,0.13,9.73L16.5,61.23L64.96,111.2L64.96,111.2L64.96,111.2z"/>
        </g>
      </svg>
      {t('back')}
    </StyledRoot>
  )
}