import { AdminLayout } from '../layouts'


export const WebsitePage = () => {
  return (
    <AdminLayout>
      WebsitePage
    </AdminLayout>
  );
}
