import styled from 'styled-components'
import { Spinner } from './Spinner.component'
import { forwardRef } from 'react'


const StyledRoot = styled.div`
  box-sizing: border-box;
  display: grid;
  align-content: center;
  align-items: center;
  justify-items: center;
  grid-column-gap: 10px;
  padding: 0 20px;
  border-radius: ${({ theme }) => theme.borderRadius.formElement};
  
  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  color: ${({ theme }) => theme.color.textPrimary};
  
  &.disabled {
    color: ${({ theme }) => theme.color.textSecondary};
    opacity: 0.8;
    pointer-events: none;
  }
`

const StyledTextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 10px;
  border: none;
  resize: vertical;

  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  
  background: none;
  color: ${({ theme }) => theme.color.textPrimary};
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.color.textSecondary};
  }

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    padding: 15px 10px;
  }
`

export const TextArea = forwardRef(({ loading, as, slotBefore, slotAfter, ...restProps }, ref) => {
  return (
    <StyledRoot
      className={restProps.disabled ? 'disabled' : ''}
      style={{ gridTemplateColumns: (slotBefore ? 'auto ' : '') + '1fr' + ((slotAfter || loading) ? ' auto' : '') }}
    >
      {slotBefore}
      <StyledTextArea ref={ref} rows={3} {...restProps} />
      {loading ? <Spinner /> : slotAfter}
    </StyledRoot>
  )
})