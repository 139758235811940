const _pattern = /[a-zA-Z0-9_\-\+\.]/

const _getRandomByte = () => {
  // http://caniuse.com/#feat=getrandomvalues
  if (window.crypto && window.crypto.getRandomValues) {
    const result = new Uint8Array(1)
    window.crypto.getRandomValues(result)
    return result[0]
  } else if (window.msCrypto && window.msCrypto.getRandomValues) {
    const result = new Uint8Array(1)
    window.msCrypto.getRandomValues(result)
    return result[0]
  } else {
    return Math.floor(Math.random() * 256)
  }
}

export const generatePassword = () => {
  return Array
    .apply(null, { 'length': 12 })
    .map(() => {
      let result
      while (true) {
        result = String.fromCharCode(_getRandomByte())
        if (_pattern.test(result)) {
          return result
        }
      }
    }, this)
    .join('')
}