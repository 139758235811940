import { makeRequest } from './make-request.api'


export const getUserRequest = (id) => {
  return makeRequest('/user/' + id)
}

export const putUserRequest = (payload) => {
  return makeRequest('/user', {
    method: 'PUT',
    body: payload
  })
}

export const postUserRequest = (id, payload) => {
  return makeRequest('/user/' + id, {
    method: 'POST',
    body: payload
  })
}

export const deleteUserRequest = (id) => {
  return makeRequest('/user/' + id, {
    method: 'DELETE',
  })
}