import { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { useMouseMove3dPerspective } from '../hooks'


const StyledButton = styled.button`
  box-sizing: border-box;
  width: 80px;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
  padding: 16px;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  
  font-size: 10px;
  text-decoration: none;
  
  background-color: transparent;
  color: ${({ theme }) => theme.color.textPrimary};
  
  transition: 0.2s;
  cursor: pointer;
  transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis));
  user-select: none;
  -webkit-user-drag: none; // Prevents dragging of the link \<\a\/>
  
  svg {
    fill: currentColor;
  }

  &.active {
    color: ${({ theme }) => theme.color.success};
    background-color: ${({ theme }) => theme.color.backgroundSecondary};
  }

  @media (hover: hover) {
    &:hover {
      transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(1.05);
      background-color: ${({ theme }) => theme.color.backgroundSecondary};
    }
  }
  
  &:active {
    transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(0.98);
  }

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    width: 100%;
    padding: 6px 12px 8px;
    border-radius: 0;

    svg {
      width: 20px;
    }
  }
`

export const NavBarItem = ({ to, icon, label }) => {
  const rootRef = useRef()
  useMouseMove3dPerspective(rootRef)

  return (
    <StyledButton ref={rootRef} as={NavLink} to={to}>
      {icon}
      <span>{label}</span>
    </StyledButton>
  )
}