import styled from 'styled-components'

import { ROUTE } from '../routing'
import { useIsStandaloneApp } from '../hooks'
import { classNames } from '../utils'
import { NavBarItem } from './NavBarItem.component'


const StyledRoot = styled.nav`
  z-index: ${({ theme }) => theme.zIndex.navBar};

  overflow: hidden;
  display: grid;
  align-content: flex-start;
  grid-template-columns: 1fr;
  grid-row-gap: 2px;
  border-right: 1px solid ${({ theme }) => theme.color.border};
  padding: 20px;
  
  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  
  transition: 0.2s;

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;

    grid-template-columns: repeat(2, 1fr);

    padding: 0;
    border-right: none;
    border-top: 1px solid ${({ theme }) => theme.color.border};
    
    &.standalone-app {
      & > a {
        padding-bottom: 24px !important;
      }
    }
  }
`

export const NavBar = () => {
  const isStandaloneApp = useIsStandaloneApp()

  return (
    <StyledRoot className={classNames({
      'standalone-app': isStandaloneApp,
    })}>
      <NavBarItem label="Dashboard" to={ROUTE.dashboard} icon={(
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.8332 4.78331C12.8332 3.03331 12.0865 2.33331 10.2315 2.33331H5.51817C3.66317 2.33331 2.9165 3.03331 2.9165 4.78331V8.04998C2.9165 9.79998 3.66317 10.5 5.51817 10.5H10.2315C12.0865 10.5 12.8332 9.79998 12.8332 8.04998V4.78331Z"/>
          <path
            d="M12.8332 15.2833C12.8332 13.5333 12.0865 12.8333 10.2315 12.8333H5.51817C3.66317 12.8333 2.9165 13.5333 2.9165 15.2833V23.2166C2.9165 24.9666 3.66317 25.6666 5.51817 25.6666H10.2315C12.0865 25.6666 12.8332 24.9666 12.8332 23.2166V15.2833Z"/>
          <path
            d="M25.0832 4.78331C25.0832 3.03331 24.3365 2.33331 22.4815 2.33331H17.7682C15.9132 2.33331 15.1665 3.03331 15.1665 4.78331V12.7166C15.1665 14.4666 15.9132 15.1666 17.7682 15.1666H22.4815C24.3365 15.1666 25.0832 14.4666 25.0832 12.7166V4.78331Z"/>
          <path
            d="M25.0832 19.95C25.0832 18.2 24.3365 17.5 22.4815 17.5H17.7682C15.9132 17.5 15.1665 18.2 15.1665 19.95V23.2167C15.1665 24.9667 15.9132 25.6667 17.7682 25.6667H22.4815C24.3365 25.6667 25.0832 24.9667 25.0832 23.2167V19.95Z"/>
        </svg>
      )}/>
      <NavBarItem label="Investors" to={ROUTE.investors} icon={(
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.9585 7.87501C4.9585 10.8733 7.3035 13.3 10.3602 13.405C10.4535 13.3933 10.5468 13.3933 10.6168 13.405C10.6402 13.405 10.6518 13.405 10.6752 13.405C10.6868 13.405 10.6868 13.405 10.6985 13.405C13.6852 13.3 16.0302 10.8733 16.0418 7.87501C16.0418 4.81834 13.5568 2.33334 10.5002 2.33334C7.4435 2.33334 4.9585 4.81834 4.9585 7.87501Z"/>
          <path
            d="M4.58593 16.507C3.10426 17.4987 2.2876 18.8404 2.2876 20.2754C2.2876 21.7104 3.10426 23.0404 4.57426 24.0204C6.2076 25.117 8.35426 25.6654 10.5009 25.6654C12.6476 25.6654 14.7943 25.117 16.4276 24.0204C17.8976 23.0287 18.7143 21.6987 18.7143 20.252C18.7026 18.817 17.8976 17.487 16.4276 16.507C13.1726 14.337 7.86426 14.337 4.58593 16.507Z"/>
          <path
            d="M19.6693 13.0762C19.6576 13.0762 19.6576 13.0762 19.6459 13.0762H19.6109C19.5409 13.0762 19.4709 13.0762 19.4126 13.0995C18.2809 13.1578 17.2426 12.7962 16.4609 12.1312C17.6626 11.0578 18.3509 9.44784 18.2109 7.69784C18.1293 6.75284 17.8026 5.8895 17.3126 5.1545C17.7559 4.93284 18.2693 4.79284 18.7943 4.74617C21.0809 4.54784 23.1226 6.25117 23.3209 8.56117C23.5076 10.8245 21.8976 12.8078 19.6693 13.0762Z"/>
          <path
            d="M23.6232 22.1321C22.4565 22.7738 20.9865 23.0771 19.5282 23.0421C20.3682 22.2838 20.8582 21.3388 20.9515 20.3355C21.0682 18.8888 20.3798 17.5005 19.0032 16.3921C18.2215 15.7738 17.3115 15.2838 16.3198 14.9221C18.8982 14.1755 22.1415 14.6771 24.1365 16.2871C25.2098 17.1505 25.7582 18.2355 25.6532 19.3555C25.5598 20.4871 24.8365 21.4671 23.6232 22.1321Z"/>
        </svg>
      )}/>
      {/*<NavBarItem label="Website" to={ROUTE.home} icon={(*/}
      {/*  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
      {/*    <path*/}
      {/*      d="M12.5598 18.94C11.1598 20.34 8.85977 20.34 7.44977 18.94L1.05977 12.55C-0.340234 11.15 -0.340234 8.85 1.05977 7.44L7.44977 1.05C8.84977 -0.35 11.1498 -0.35 12.5598 1.05L18.9498 7.44C20.3498 8.85 20.3498 11.15 18.9498 12.55L12.5598 18.94Z"/>*/}
      {/*  </svg>*/}
      {/*)}/>*/}
      {/*<NavBarItem label="Settings" to={ROUTE.settings} icon={(*/}
      {/*  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
      {/*    <path fillRule="evenodd" clipRule="evenodd"*/}
      {/*          d="M16.45 4.3714C16.97 3.4614 16.66 2.3014 15.75 1.7814L14.02 0.791401C13.23 0.321401 12.21 0.601401 11.74 1.3914L11.63 1.5814C10.73 3.1514 9.25 3.1514 8.34 1.5814L8.23 1.3914C7.78 0.601401 6.76 0.321401 5.97 0.791401L4.24 1.7814C3.33 2.3014 3.02 3.4714 3.54 4.3814C4.45 5.9414 3.71 7.2214 1.9 7.2214C0.86 7.2214 0 8.0714 0 9.1214V10.8814C0 11.9214 0.85 12.7814 1.9 12.7814C3.71 12.7814 4.45 14.0614 3.54 15.6314C3.02 16.5414 3.33 17.7014 4.24 18.2214L5.97 19.2114C6.76 19.6814 7.78 19.4014 8.25 18.6114L8.36 18.4214C9.26 16.8514 10.74 16.8514 11.65 18.4214L11.76 18.6114C12.23 19.4014 13.25 19.6814 14.04 19.2114L15.77 18.2214C16.68 17.7014 16.99 16.5314 16.47 15.6314C15.56 14.0614 16.3 12.7814 18.11 12.7814C19.15 12.7814 20.01 11.9314 20.01 10.8814V9.1214C20 8.0814 19.15 7.2214 18.1 7.2214C16.29 7.2214 15.55 5.9414 16.45 4.3714ZM6.75 10.0014C6.75 8.2114 8.21 6.7514 10 6.7514C11.79 6.7514 13.25 8.2114 13.25 10.0014C13.25 11.7914 11.79 13.2514 10 13.2514C8.21 13.2514 6.75 11.7914 6.75 10.0014Z"/>*/}
      {/*  </svg>*/}
      {/*)}/>*/}
    </StyledRoot>
  )
}