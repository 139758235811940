import { AdminLayout } from '../layouts'


export const SettingsPage = () => {
  return (
    <AdminLayout>
      SettingsPage
    </AdminLayout>
  );
}
