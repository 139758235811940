import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { getDashboardRequest } from '../services'
import { AdminLayout } from '../layouts'
import { DetailsItem, Spinner, Typography } from '../components'
import { formatPrice, formatToStockPrice, isNegativeNumber } from '../utils'


const StyledDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px 30px;

  & > :first-child {
    grid-column: 1 / 3;
  }

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;

    & > :first-child {
      grid-column: 1;
    }
  }
`

export const DashboardPage = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState(null)

  useEffect(() => {
    setLoading(true)
    getDashboardRequest()
      .then(setDashboardData)
      .finally(() => setLoading(false))
  }, [])

  return (
    <AdminLayout>

      <StyledDetailsGrid>
        <DetailsItem size="big" caption={t('totalAmountInvestors')} value={loading ? <Spinner/> : dashboardData?.investorsTotal}/>
        <DetailsItem size="big" caption={t('totalAmountInvested')} value={loading ? <Spinner/> : formatPrice(dashboardData?.investsTotal, {
          showPositiveSign: false,
        })}/>
        <DetailsItem size="big" caption={t('totalCurrentBalance')} value={loading ? <Spinner/> : formatPrice(dashboardData?.investsTotalWithRevenue, {
          showPositiveSign: false,
        })}/>
        <DetailsItem size="big" caption={t('totalRevenueAllTime')}>
          <Typography
            color={isNegativeNumber(dashboardData?.revenueTotalPercent) ? 'danger' : 'success'}
          >{loading ? <Spinner/> : formatToStockPrice(dashboardData?.revenueTotal, dashboardData?.revenueTotalPercent)}</Typography>
        </DetailsItem>
        <DetailsItem size="big" caption={t('totalRevenueFor24h')}>
          <Typography
            color={isNegativeNumber(dashboardData?.revenue24hTotalPercent) ? 'danger' : 'success'}
          >{loading ? <Spinner/> : formatToStockPrice(dashboardData?.revenue24hTotal, dashboardData?.revenue24hTotalPercent)}</Typography>
        </DetailsItem>
      </StyledDetailsGrid>

    </AdminLayout>
  )
}
