import { useRef } from 'react'
import styled from 'styled-components'

import { classNames } from '../utils'
import { Spinner } from './Spinner.component'
import { useMouseMove3dPerspective } from '../hooks'


const StyledRoot = styled.button`
  width: 42px;
  height: 42px;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadius.menu};

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;

  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  color: ${({ theme }) => theme.color.textPrimary};
  outline: none;

  transition: 0.2s;
  transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis));
  user-select: none;

  // COLOR
  &.success {
    background-color: ${({ theme }) => theme.color.success}
  }

  &.danger {
    background-color: ${({ theme }) => theme.color.danger}
  }

  &.warning {
    background-color: ${({ theme }) => theme.color.warning}
  }

  &.info {
    background-color: ${({ theme }) => theme.color.info}
  }

  &.white {
    background-color: ${({ theme }) => theme.color.textPrimary};
    color: ${({ theme }) => theme.color.backgroundPrimary};
  }
  
  // STATE
  &:focus {
    border-color: ${({ theme }) => theme.color.textSecondary};
  }

  &.bordered {
    border: 1px solid currentColor;
  }

  &.loading {
    cursor: wait;
  }

  &[disabled] {
    filter: brightness(0.8);
    cursor: not-allowed;
  }

  &.active {
    background-color: ${({ theme }) => theme.color.textPrimaryHover};
    transform: none;
  }

  &:not([disabled]):not(.active):not(.loading) {
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        border: 1px solid ${({ theme }) => theme.color.textPrimary};
        background-color: ${({ theme }) => theme.color.backgroundSecondary};
        transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(1.1);
      }
    }

    &:active {
      transform: scale(0.94);
    }
  }

  svg {
    fill: currentColor;
    width: 24px;
  }

  .spinner {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: currentColor;
  }

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    width: 52px;
    height: 52px;

    .spinner {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }
`

/**
 * @param {'success' | 'danger' | 'warning' | 'info' | 'white'} color
 * @param loading
 * @param disabled
 * @param active
 * @param bordered
 * @param color
 * @param children
 * @param restProps
 * @returns {JSX.Element}
 * @constructor
 */
export const IconButton = ({ loading, disabled, active, bordered, color, children, ...restProps }) => {
  const rootRef = useRef()

  useMouseMove3dPerspective(rootRef)

  return (
    <StyledRoot ref={rootRef} className={classNames({
      active,
      bordered,
      loading,
      color,
    })} {...restProps} disabled={loading || disabled}>{loading ? <Spinner className="spinner"/> : children}</StyledRoot>
  )
}