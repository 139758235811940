import styled from 'styled-components'
import { Typography } from './Typography.component'
import { Paper } from './Paper.component'
import { IconButton } from './IconButton.component'
import { Fragment, isValidElement, useState } from 'react'

const isReactFragment = (element) => {
  return isValidElement(element) && element.type === Fragment;
};

const StyledRoot = styled.div`
  position: relative;

  display: grid;
  grid-row-gap: 35px;

  padding: 20px 40px 40px;

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    padding: 30px 20px 48px;
  }
`

const StyledTitle = styled.div`
  margin-right: 110px;
`

const StyledControlsWrapper = styled.div`
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 30px;
  align-items: flex-end;

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    grid-template-columns: 1fr !important;
  }
`

const StyledActions = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
`

const StyledSaveButton = styled(IconButton)`
  color: ${({ theme }) => theme.color.success};

  transform: translateX(100%);
  opacity: 0;
  pointer-events: none;

  transition: 0.2s;

  &.shown {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  }
`

export const FormSection = ({ hasEditMode, title, children, gridTemplateColumns, onSubmit }) => {
  const [editMode, setEditMode] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const enterEditMode = () => {
    setEditMode(true)
  }

  const cancelEditMode = () => {
    setEditMode(false)
  }

  const save = async () => {
    setSubmitLoading(true)
    await onSubmit()
    setEditMode(false)
    setSubmitLoading(false)
  }

  const childrenRendered = typeof children === 'function' ? children({ editMode, loading: submitLoading }) : children
  const controlsCount = isReactFragment(childrenRendered) ? childrenRendered.props.children.length : children.length

  return (
    <Paper as={StyledRoot}>
      <Typography as={StyledTitle} variant="paper-title">{title}</Typography>

      <StyledControlsWrapper style={{ gridTemplateColumns: gridTemplateColumns ?? `repeat(${controlsCount}, 1fr)` }}>
        {childrenRendered}
      </StyledControlsWrapper>

      {hasEditMode && <StyledActions>
        <IconButton color='success' disabled={!editMode} type="button" className={(editMode && !submitLoading) ? 'shown' : ''} as={StyledSaveButton} onClick={save}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="green" viewBox="0 0 122.88 102.97">
            <path className="cls-1"
                  d="M4.82,69.68c-14.89-16,8-39.87,24.52-24.76,5.83,5.32,12.22,11,18.11,16.27L92.81,5.46c15.79-16.33,40.72,7.65,25.13,24.07l-57,68A17.49,17.49,0,0,1,48.26,103a16.94,16.94,0,0,1-11.58-4.39c-9.74-9.1-21.74-20.32-31.86-28.9Z"/>
          </svg>
        </IconButton>

        {
          editMode ?
            (
              <IconButton type="button" loading={submitLoading} onClick={cancelEditMode}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" viewBox="0 0 122.88 122.88">
                  <path className="cls-1"
                        d="M6,6H6a20.53,20.53,0,0,1,29,0l26.5,26.49L87.93,6a20.54,20.54,0,0,1,29,0h0a20.53,20.53,0,0,1,0,29L90.41,61.44,116.9,87.93a20.54,20.54,0,0,1,0,29h0a20.54,20.54,0,0,1-29,0L61.44,90.41,35,116.9a20.54,20.54,0,0,1-29,0H6a20.54,20.54,0,0,1,0-29L32.47,61.44,6,34.94A20.53,20.53,0,0,1,6,6Z"/>
                </svg>
              </IconButton>
            )
            : (
              <IconButton type="button" onClick={enterEditMode}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M7.81 2C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2H7.81Z"
                        fill="white"/>
                  <path
                    d="M17.9602 10.0361C17.765 10.2314 17.4484 10.2314 17.2531 10.0361L14.2435 7.02652C14.0483 6.83126 14.0483 6.51468 14.2435 6.31942L14.7075 5.85542C15.7263 4.83663 17.3992 4.83663 18.4242 5.86171C19.443 6.8805 19.443 8.55333 18.4242 9.57213L17.9602 10.0361Z"
                    fill="black"/>
                  <path
                    d="M5.87122 15.2321L5.52534 16.5968C5.12914 18.1879 6.09762 19.1564 7.68241 18.7539L9.05338 18.4143C9.43699 18.3199 9.99041 18.0055 10.2734 17.7225L16.3093 11.6866C16.5046 11.4913 16.5046 11.1747 16.3093 10.9795L13.2997 7.96989C13.1045 7.77462 12.7879 7.77462 12.5926 7.96989L6.55671 14.0058C6.27371 14.2888 5.95927 14.8422 5.87122 15.2321Z"
                    fill="black"/>
                </svg>
              </IconButton>
            )
        }
      </StyledActions>}
    </Paper>
  )
}