export const EditIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.81 2C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2H7.81Z"
          fill="white"/>
    <path
      d="M17.9602 10.0361C17.765 10.2314 17.4484 10.2314 17.2531 10.0361L14.2435 7.02652C14.0483 6.83126 14.0483 6.51468 14.2435 6.31942L14.7075 5.85542C15.7263 4.83663 17.3992 4.83663 18.4242 5.86171C19.443 6.8805 19.443 8.55333 18.4242 9.57213L17.9602 10.0361Z"
      fill="black"/>
    <path
      d="M5.8711 15.2321L5.52521 16.5968C5.12902 18.1879 6.0975 19.1564 7.68229 18.7539L9.05325 18.4143C9.43687 18.3199 9.99029 18.0055 10.2733 17.7225L16.3092 11.6866C16.5045 11.4913 16.5045 11.1747 16.3092 10.9795L13.2996 7.96989C13.1043 7.77462 12.7878 7.77462 12.5925 7.96989L6.55658 14.0058C6.27359 14.2888 5.95914 14.8422 5.8711 15.2321Z"
      fill="black"/>
  </svg>
)