import { isNegativeNumber } from './is-negative-number.util'
import { formatPrice } from './format-price.util'

export const formatToStockPrice = (price, percent, isPercentShownFirst) => {
  const percentSign = isNegativeNumber(percent) ? '-' : '+'

  // To locale string is used to add commas to the number: 1000 -> 1,000
  // Math.abs is used to remove the sign from a negative number
  const priceFormatted = formatPrice(price, {
    showPositiveSign: true,
    currencySign: '$'
  })

  const percentFormatted = percent === undefined ? '' : `${percentSign}${Math.abs(percent)}%`

  if (isPercentShownFirst) {
    return `${percentFormatted} (${priceFormatted})`
  }

  return `${priceFormatted} (${percentFormatted})`
}