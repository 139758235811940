export const LANGUAGE = {
  en: "en",
  uk: "uk",
  ru: "ru",
  cs: "cs",
  pl: "pl",
  es: "es",
  pt: "pt",
  lv: "lv",
  it: "it",
  de: "de",
  fr: "fr",
  nl: "nl"
}