import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { AuthorizationContext } from '../contexts'
import { NonAuthorizedLayout } from '../layouts'
import { Button, Field, IconButton, Input, Paper, Typography } from '../components'


const StyledRoot = styled.div`
  padding: 50px 60px;
  max-width: 628px;
  margin: 60px auto 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 25px;

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    margin-top: 20px;
    padding: 20px;
  }
`

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;
`

export const LoginPage = () => {
  const { t } = useTranslation()

  const { login } = useContext(AuthorizationContext)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const toggleShowPassword = () => setShowPassword(!showPassword)

  const handleSubmit = async (event) => {
    event.preventDefault()

    const formData = new FormData(event.target)
    const payload = Object.fromEntries(formData)

    setLoading(true)
    login({
      username: payload.username.trim(),
      password: payload.password.trim(),
    })
    setLoading(false)
  }

  return (
    <NonAuthorizedLayout>
      <Paper as={StyledRoot}>
        <Typography variant="auth-title" align="center">{t('welcome')}</Typography>
        <StyledForm onSubmit={handleSubmit}>
          <Field isLabelBold label={t('login')}>
            <Input autoFocus={true} placeholder={t('yourLogin')} slotBefore={(
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2C9.38 2 7.25 4.13 7.25 6.75Z"
                  fill="white"/>
                <path
                  d="M6.93094 14.1489C5.66094 14.9989 4.96094 16.1489 4.96094 17.3789C4.96094 18.6089 5.66094 19.7489 6.92094 20.5889C8.32094 21.5289 10.1609 21.9989 12.0009 21.9989C13.8409 21.9989 15.6809 21.5289 17.0809 20.5889C18.3409 19.7389 19.0409 18.5989 19.0409 17.3589C19.0309 16.1289 18.3409 14.9889 17.0809 14.1489C14.2909 12.2889 9.74094 12.2889 6.93094 14.1489Z"
                  fill="white"/>
              </svg>
            )} type="text" id="username" name="username" required/>
          </Field>
          <Field isLabelBold label={t('password')}>
            <Input placeholder={t('yourPassword')} slotBefore={(
              <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.625 7.5H13V5C13 2.2425 10.7575 0 8 0C5.2425 0 3 2.2425 3 5V7.5H2.375C1.87792 7.50066 1.40139 7.69842 1.04991 8.0499C0.698418 8.40139 0.500661 8.87792 0.5 9.375V18.125C0.5 19.1592 1.34167 20 2.375 20H13.625C14.6583 20 15.5 19.1592 15.5 18.125V9.375C15.5 8.34083 14.6583 7.5 13.625 7.5ZM4.66667 5C4.66667 3.16167 6.16167 1.66667 8 1.66667C9.83833 1.66667 11.3333 3.16167 11.3333 5V7.5H4.66667V5ZM8.83333 13.935V15.8333C8.83333 16.0543 8.74554 16.2663 8.58926 16.4226C8.43297 16.5789 8.22101 16.6667 8 16.6667C7.77899 16.6667 7.56702 16.5789 7.41074 16.4226C7.25446 16.2663 7.16667 16.0543 7.16667 15.8333V13.935C6.67083 13.6458 6.33333 13.1142 6.33333 12.5C6.33333 11.5808 7.08083 10.8333 8 10.8333C8.91917 10.8333 9.66667 11.5808 9.66667 12.5C9.66667 13.1142 9.32917 13.6458 8.83333 13.935Z"
                  fill="white"/>
              </svg>
            )} slotAfter={(
              <IconButton type="button" onClick={toggleShowPassword}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969ZM7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997Z"
                        fill="white"/>
                  <path
                    d="M9.14844 12.0006C9.14844 13.5706 10.4284 14.8506 11.9984 14.8506C13.5684 14.8506 14.8584 13.5706 14.8584 12.0006C14.8584 10.4306 13.5684 9.14062 11.9984 9.14062C10.4284 9.14062 9.14844 10.4206 9.14844 12.0006Z"
                    fill="white"/>
                </svg>
              </IconButton>
            )} type={showPassword ? 'text' : 'password'} id="password" name="password" required/>
          </Field>
          <Button loading={loading} type="submit" variant="success">{t('loginButton')}</Button>
        </StyledForm>
      </Paper>
    </NonAuthorizedLayout>
  )
}
