import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'

import { AuthorizationContext } from '../contexts'
import { DEFAULT_ADMIN_ROUTE, DEFAULT_INVESTOR_ROUTE, ROUTE } from '../routing'


export const RoutePolicy = ({ onlyNonAuth, onlyAuth, onlyNotBanned, onlyAdmin, onlyInvestor, children }) => {
  const { isAuthorized, isAdmin, me } = useContext(AuthorizationContext)

  const banned = me?.investor?.banned

  if (isAuthorized && onlyNotBanned && banned) {
    return <Navigate to={ROUTE.banned} replace />
  }

  if (isAuthorized && onlyAdmin && !isAdmin) {
    return <Navigate to={DEFAULT_INVESTOR_ROUTE} replace />
  }

  if (isAuthorized && onlyInvestor && isAdmin) {
    return <Navigate to={DEFAULT_ADMIN_ROUTE} replace />
  }

  if (onlyAuth && !isAuthorized) {
    return <Navigate to={ROUTE.login} replace />
  }

  if (onlyNonAuth && isAuthorized) {
    return <Navigate to={isAdmin ? DEFAULT_ADMIN_ROUTE : DEFAULT_INVESTOR_ROUTE} replace />
  }

  return children
}