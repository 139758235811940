import { useRef } from 'react'
import styled from 'styled-components'

import { useMouseMove3dPerspective } from '../hooks'


const StyledRoot = styled.button`
 display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border: 1px solid currentColor;
  border-radius: 50%;

  font-size: 14px;
  font-weight: 700;

  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  color: ${({ theme }) => theme.color.textPrimary};
  transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis));

  cursor: pointer;
  
  transition: 0.2s;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.textPrimaryHover};
      transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(1.05);
    }
  }
  
  &:active {
    transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(0.94);
  }

  &.active {
    background-color: ${({ theme }) => theme.color.textPrimaryHover};
  }
`

export const CloseButton = (props) => {
  const rootRef = useRef()
  useMouseMove3dPerspective(rootRef)

  return (
    <StyledRoot {...props} ref={rootRef}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.10413 12.8959L12.8958 1.10425" stroke="white" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M12.8958 12.8959L1.10413 1.10425" stroke="white" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
      </svg>
    </StyledRoot>
  )
}