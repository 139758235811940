import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  getLocalStorageMe,
  getLocalStorageToken,
  removeLocalStorageMe,
  removeLocalStorageToken,
  setLocalStorageMe,
  setLocalStorageToken
} from '../services/local-storage'
import { getMeRequest, loginRequest } from '../services'
import { DEFAULT_ADMIN_ROUTE, DEFAULT_INVESTOR_ROUTE, ROUTE } from '../routing'
import { USER_ROLE } from '../models'
import { USER_UNAUTHORIZED_REQUEST_CUSTOM_EVENT } from '../custom-events'


export const AuthorizationContext = createContext(null)

export const AuthorizationProvider = ({ children }) => {
  const navigate = useNavigate()
  const [isAuthorized, setIsAuthorized] = useState(!!getLocalStorageToken())
  const [me, setMe] = useState(getLocalStorageMe())

  const { user } = me || { user: null }

  const isAdmin = user?.role === USER_ROLE.admin

  const login = async (payload) => {
    try {
      const { token, ...me } = await loginRequest(payload)
      const { user } = me

      setLocalStorageToken(token)
      setLocalStorageMe(me)

      setMe(me)
      setIsAuthorized(true)

      const homePageUrl = user.role === USER_ROLE.admin ? DEFAULT_ADMIN_ROUTE : DEFAULT_INVESTOR_ROUTE

      navigate(homePageUrl)
    } catch (error) {
      // Do nothing
    }
  }

  const logout = () => {
    removeLocalStorageToken()
    removeLocalStorageMe()

    setMe(null)
    setIsAuthorized(false)
    navigate(ROUTE.login)
  }

  const refetchMe = () => {
    return getMeRequest().then((me) => {
      setMe(me)
      setLocalStorageMe(me)
    })
  }

  useEffect(() => {
    const unauthorizedListener = () => {
      logout()
    }

    window.addEventListener(USER_UNAUTHORIZED_REQUEST_CUSTOM_EVENT, unauthorizedListener)

    return () => {
      window.removeEventListener(USER_UNAUTHORIZED_REQUEST_CUSTOM_EVENT, unauthorizedListener)
    }
  }, [])

  useEffect(() => {
    if (isAuthorized) {
      refetchMe()
    }
  }, [])

  return (
    <AuthorizationContext.Provider value={{ isAuthorized, isAdmin, me, login, logout, refetchMe }}>
      {children}
    </AuthorizationContext.Provider>
  )
}
