import { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CONTACT_US_LINK_ENV } from '../envs'
import { NonAuthorizedLayout } from '../layouts'
import { Button, ShiningWrapper, Typography } from '../components'


const StyledRoot = styled.div`
  --header-height: 100px;
  --padding-top: 126px;
  --padding-bottom: 96px;

  position: relative;

  box-sizing: border-box;
  display: grid;
  grid-row-gap: 30px;
  min-height: calc(100vh - var(--header-height) - 70px);
  max-width: unset;
  padding: 96px;
  justify-items: flex-start;
  align-content: center;

  & > *:not([data-particles]) {
    z-index: 1;
  }

  a {
    margin-top: 10px;
  }

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    grid-row-gap: 20px;
    padding: 20px;
  }
`

const StyledContactUsWrapper = styled.div`
  animation: appear 1s forwards;
  animation-delay: 1s;

  transform: scale(0);
  opacity: 0;

  @keyframes appear {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    justify-self: stretch;
    display: flex;
    flex-direction: column;
  }
`

const StyledCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const LandingPage = () => {
  const { t } = useTranslation()

  useLayoutEffect(() => {
    let gl

    var canvas = document.querySelector('canvas')
    var ut, st = Date.now()

    function initShaders(gl, vertexShaderId, fragmentShaderId) {
      var vertexEl = document.querySelector(vertexShaderId)
      var vertexShader = gl.createShader(gl.VERTEX_SHADER)
      gl.shaderSource(vertexShader, vertexEl.text)
      gl.compileShader(vertexShader)

      if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
        debugger
      }

      var fragmentEl = document.querySelector(fragmentShaderId)
      var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)
      gl.shaderSource(fragmentShader, fragmentEl.text)
      gl.compileShader(fragmentShader)

      if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
        debugger
      }

      var program = gl.createProgram()
      gl.attachShader(program, vertexShader)
      gl.attachShader(program, fragmentShader)
      gl.linkProgram(program)
      gl.useProgram(program)

      return program
    }

    function initGraphics() {
      gl = canvas.getContext('webgl')
      var width = canvas.width
      var height = canvas.height
      gl.viewport(0, 0, width, height)

      var program = initShaders(gl, '#sv', '#sf')
      var buffer = gl.createBuffer()
      gl.bindBuffer(gl.ARRAY_BUFFER, buffer)

      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array([-1, 1, -1, -1, 1, -1, 1, 1]),
        gl.STATIC_DRAW
      )

      var vPosition = gl.getAttribLocation(program, 'vPosition')
      gl.vertexAttribPointer(vPosition, 2, gl.FLOAT, false, 0, 0)
      gl.enableVertexAttribArray(vPosition)

      ut = gl.getUniformLocation(program, 'time')
      var resolution = new Float32Array([canvas.width, canvas.height])
      gl.uniform2fv(gl.getUniformLocation(program, 'resolution'), resolution)
    }

    function render() {
      gl.uniform1f(ut, (Date.now() - st) / 1000)
      gl.drawArrays(gl.TRIANGLE_FAN, 0, 4)
      requestAnimationFrame(render)
    }

    initGraphics()
    render()
  }, [])

  return (
    <NonAuthorizedLayout contentAs={StyledRoot}>
      <StyledCanvas/>
      <ShiningWrapper>
        <Typography variant="section-description">{t('title', { ns: 'landing' })} DOV Invest</Typography>
      </ShiningWrapper>
      <ShiningWrapper>
        <Typography variant="section-title">{t('slogan', { ns: 'landing' })}</Typography>
      </ShiningWrapper>
      <ShiningWrapper>
        <Typography variant="section-description">{t('description', { ns: 'landing' })}</Typography>
      </ShiningWrapper>
      <StyledContactUsWrapper>
        <Button as="a" href={CONTACT_US_LINK_ENV} target="_blank" iconBefore={
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_126_40)">
              <path
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0C5.37259 0 0 5.37259 0 12C0 18.6274 5.37259 24 12 24Z"
                fill="white"/>
              <path
                d="M4.99432 11.8959C4.99432 11.8959 10.9943 9.43349 13.0752 8.56642C13.8729 8.21962 16.5781 7.10976 16.5781 7.10976C16.5781 7.10976 17.8267 6.62424 17.7226 7.80341C17.6879 8.28898 17.4105 9.98837 17.133 11.8265C16.7168 14.4277 16.266 17.2716 16.266 17.2716C16.266 17.2716 16.1966 18.0693 15.607 18.208C15.0174 18.3468 14.0463 17.7225 13.8729 17.5837C13.7342 17.4797 11.2718 15.919 10.37 15.156C10.1272 14.9479 9.8498 14.5318 10.4047 14.0462C11.6533 12.9017 13.1446 11.4797 14.0463 10.578C14.4625 10.1618 14.8787 9.19071 13.1446 10.3699C10.6822 12.0693 8.25443 13.6647 8.25443 13.6647C8.25443 13.6647 7.6995 14.0115 6.65905 13.6993C5.61856 13.3872 4.40469 12.971 4.40469 12.971C4.40469 12.971 3.57237 12.4508 4.99432 11.8959Z"
                fill="#1FDE00"/>
            </g>
            <defs>
              <clipPath id="clip0_126_40">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        } variant="success" style={{ textTransform: 'uppercase', fontSize: '14px' }}>{t('contactUs')}</Button>
      </StyledContactUsWrapper>
    </NonAuthorizedLayout>
  )
}