import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Highcharts from 'highcharts'

import { INVESTMENT_CATEGORIES } from '../constants'
import { DonutChart } from './DonutChart.component'
import { Typography } from './Typography.component'
import { InputRange } from './InputRange.component'
import { classNames } from '../utils'
import { LegendItem } from './LegendItem.component'


// Make Highcharts lines smooth
Highcharts.seriesTypes.line.prototype.getPointSpline = Highcharts.seriesTypes.spline.prototype.getPointSpline

const StyledRoot = styled.div`
  min-width: 0;
  display: grid;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  justify-items: center;
  
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

const StyledChartWrapper = styled.div`
  position: relative;
`

const StyledChartSelectedSeries = styled.span`
   position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 35%;

  font-weight: normal !important;
`

const StyledLegendItem = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto 1fr;
  align-items: center;

  cursor: pointer;
  
  &.edit-mode {
    grid-template-columns: 1fr;
    align-items: start;
    cursor: default;
  }
  
  &:not(.edit-mode) {
    &:focus {
      transform: scale(0.99);
    }

    @media (hover: hover) {
      &:hover {
        filter: brightness(0.8);
      }
    }

    &:active {
      transform: scale(0.98);
    }
  }
`

const StyledRangeInputIndicatorWrapper = styled.div`
  position: relative;
`

const StyledIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
`

const StyledLegendItemLabel = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 3px;
  align-items: center;
`

const StyledPercentText = styled.b`
  display: inline-block;
  width: 4em;
`

const StyledLegend = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }
`

const CATEGORY_COLOR = {
  'blockchainService': '#00ff00', // Bright green
  'defi': '#33cc33', // Lime green
  'cefi': '#66cc66', // Light green
  'chain': '#99cc99', // Soft green
  'nft': '#99ff99', // Pastel green
  'blockchainInfrastructure': '#66ff66', // Vivid green
  'social': '#33ff33', // Neon green
  'gamefi': '#00cc00', // Dark green
  'stablecoin': '#339933', // Forest green
  'other': '#669966', // Olive green
  'currency': '#336633' // Deep green
}


const valueToPercent = (value) => {
  return parseFloat((value * 100 || 0).toFixed(2))
}

const floatToFixedFloat = (float) => {
  return parseFloat(float.toFixed(2))
}

const floatStrToFixedFloat = (str) => {
  return floatToFixedFloat(parseFloat(str))
}

export const InvestmentCategoriesDonutChart = ({ hideLegendWithZeroValue, disabled, editMode, data }) => {
  const { t } = useTranslation()

  const [selectedSeries, setSelectedSeries] = useState(null)
  const [localCategoriesValue, setLocalCategoriesValue] = useState({})

  let investmentCategoriesData = INVESTMENT_CATEGORIES.map(category => {
    const value = floatStrToFixedFloat(localCategoriesValue[category] ?? data?.[category])
    const percent = valueToPercent(value)
    return {
      id: category,
      name: t(category),
      value,
      percent,
      y: percent,
      color: CATEGORY_COLOR[category],
    }
  })

  if (hideLegendWithZeroValue) {
    investmentCategoriesData = investmentCategoriesData.filter(category => category.value > 0)
  }

  const updateLocalCategoryY = (id, newValue) => {
    const updatedLocalCategoriesY = { ...localCategoriesValue }
    updatedLocalCategoriesY[id] = newValue
    setLocalCategoriesValue(updatedLocalCategoriesY)
  }

  useEffect(() => {
    setSelectedSeries(investmentCategoriesData[0])
    setLocalCategoriesValue({})
  }, [data])

  const selectedSeriesId = selectedSeries?.id

  const categoryYSum = investmentCategoriesData.reduce((acc, category) => acc + category.value, 0)

  const freeY = floatToFixedFloat(1 - categoryYSum)

  return (
    <StyledRoot className={classNames({ disabled })}>
      <StyledChartWrapper>

        {/* SELECTED SERIES INSIDE CHART LABEL */}
        <Typography as={StyledChartSelectedSeries} align="center" color="primary" variant="label-bold">
          {selectedSeries?.name}
          <br/>
          <b>{localCategoriesValue[selectedSeriesId] ?? selectedSeries?.y}%</b>
        </Typography>

        {/* CHART */}
        <DonutChart data={investmentCategoriesData} onPointMouseOver={setSelectedSeries}
                    onPointClick={setSelectedSeries}/>
      </StyledChartWrapper>

      {/* CHART LEGEND */}
      <StyledLegend>
        {
          investmentCategoriesData.map((category, index) => {
            const inputStyle = {
              width: editMode ? '100%' : 28,
              color: category.color,
              opacity: editMode ? 1 : 0,
              pointerEvents: editMode ? 'auto' : 'none',
            }

            const indicatorStyle = {
              opacity: editMode ? 0 : 1,
              pointerEvents: editMode ? 'none' : 'auto',
              backgroundColor: category.color,
            }

            const handleChange = (event) => {
              const newValue = floatStrToFixedFloat(event.target.value)
              const increase = newValue > category.value

              if (increase) {
                const noFreeSpaceForIncrease = freeY + floatToFixedFloat(category.value - newValue) < 0
                if (noFreeSpaceForIncrease) {
                  event.preventDefault()
                  return
                }
              }

              updateLocalCategoryY(category.id, newValue)
            }

            return (
              <LegendItem
                key={index}
                as={StyledLegendItem}
                color="primary"
                variant="small-caption"
                className={classNames({ 'edit-mode': editMode })}
                onClick={editMode ? undefined : () => setSelectedSeries(category)}
                onMouseDown={editMode ? () => setSelectedSeries(category) : undefined}
              >
                <StyledRangeInputIndicatorWrapper>
                  <InputRange
                    style={inputStyle}
                    disabled={!editMode}
                    name={category.id}
                    type="range"
                    step={0.01}
                    value={category.value}
                    max={1}
                    onChange={handleChange}
                  />
                  <StyledIndicator style={indicatorStyle}/>
                </StyledRangeInputIndicatorWrapper>
                <StyledLegendItemLabel>
                  <StyledPercentText>{category.percent.toFixed(2)}%</StyledPercentText>
                  <span>{category.name}</span>
                </StyledLegendItemLabel>
              </LegendItem>
            )
          })
        }
      </StyledLegend>
    </StyledRoot>
  )
}