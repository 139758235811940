import { IconButton } from './IconButton.component'
import { Select } from './Select.component'


export const IconButtonSelect = ({ icon, children }) => {
  return (
    <Select
      controlComponent={({ shown, toggle }) => (
        <IconButton bordered active={shown} onClick={toggle}>{icon}</IconButton>
      )}
    >{children}</Select>
  )
}