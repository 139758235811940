import { useState } from 'react'
import styled from 'styled-components'
import { classNames } from '../utils'
import { Spinner } from './Spinner.component'


const StyledRoot = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;

  @media (max-width: ${({ theme }) => theme.screenWidth.tablet}) {
    min-width: unset;

    &.smart-responsive {
      thead {
        display: none;
      }

      tr {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        padding: 20px;

        &:not(.dragged-over) {
          &:after {
            background-color: ${({ theme }) => theme.color.backgroundSecondary};
          }

          &:hover {
            &:after {
              background-color: ${({ theme }) => theme.color.backgroundBanned};
            }
          } 
        }
      }

      [data-mobile-fake-th], td {
        padding: 5px 0;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.screenWidth.mobileSmall}) {
    &.smart-responsive {
      tr {
        grid-template-columns: 1fr;
        text-align: center;
        justify-content: center;
      }

      td {
        display: flex;
        justify-content: space-between;

        button {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
`

const StyledHead = styled.thead`
`

const StyledHeadRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.color.backgroundSecondary};
`

const StyledHeadCell = styled.th`
  padding: 10px 20px;

  text-align: left;
  font-size: 14px;
  font-weight: 400;

  color: ${({ theme }) => theme.color.textSecondary};
`

const StyledMobileHeadCell = styled(StyledHeadCell)`
  word-wrap: break-word;
  @media (min-width: ${({ theme }) => theme.screenWidth.tabletMinWidth}) {
    display: none;
  }
`


const StyledBody = styled.tbody`
`

const StyledBodyRow = styled.tr`
  color: ${({ theme }) => theme.color.textPrimary};

  border-bottom: 1px solid ${({ theme }) => theme.color.backgroundSecondary};

  &.clickable {
    position: relative;

    cursor: pointer;

    transform: scale(1);

    &:after {
      z-index: -1;
      position: absolute;
      top: 6px;
      left: 0;
      right: 0;
      bottom: 6px;
      border-radius: ${({ theme }) => theme.borderRadius.formElement};

      background-color: transparent;

      transition: 0.2s;
      content: '';
    }

    &:hover:not(.dragged-over) {
      color: ${({ theme }) => theme.color.textPrimaryHover};

      &:after {
        background-color: ${({ theme }) => theme.color.backgroundSecondary};
      }
    }

    &:active {
      &:after {
        transform: scale(0.99);
      }
    }
  }

  &.draggable {
    cursor: grab;
  }
  
  &.dragged-over {
    &:after {
      background-color: orangered;
    }
  }
`

const StyledBodyCell = styled.td`
  padding: 20px;

  font-size: 16px;
  font-weight: 700;
`


// NEED TO REWRITE THIS COMPONENT TO IMPERATIVE STYLE, NOT DECLARATIVE
export const Table = ({ draggableRows, smartResponsive, loading, head, body, onRowClick, onRowDragEnd }) => {
  const [draggedRowIndex, setDraggedRowIndex] = useState(null)
  const [draggedOverRowIndex, setDraggedOverRowIndex] = useState(null)

  return (
    <StyledRoot className={classNames({ 'smart-responsive': smartResponsive, })}>
      <StyledHead>
        <StyledHeadRow>
          {head?.map((item, index) => (
            <StyledHeadCell key={index}>
              {item}
            </StyledHeadCell>
          ))}
        </StyledHeadRow>
      </StyledHead>
      <StyledBody>
        {loading && <StyledBodyRow key="spinner">
          <StyledBodyCell align="center" colSpan={head.length} style={{ gridColumn: '1/-1' }}>
            <Spinner/>
          </StyledBodyCell>
        </StyledBodyRow>}

        {body?.map((row, index) => {
          const draggableProps = draggableRows ? {
            draggable: true,
          } : {}

          return (
            <StyledBodyRow
              key={index}
              {...draggableProps}
              className={classNames({
                clickable: onRowClick,
                'draggable': draggableRows,
                'dragged-over': index === draggedOverRowIndex,
              })}
              onDragStart={() => setDraggedRowIndex(index)}
              onDragOver={() => setDraggedOverRowIndex(index)}
              onClick={onRowClick ? () => onRowClick(row, index) : undefined}
              onDragEnd={() => {
                onRowDragEnd(draggedRowIndex, draggedOverRowIndex)
                setDraggedRowIndex(null)
                setDraggedOverRowIndex(null)
              }}
            >
              {row.map((cell, index) => (
                <StyledBodyCell key={index}>
                  {smartResponsive && head[index] && <StyledMobileHeadCell as="div" data-mobile-fake-th>
                    {head[index]}
                  </StyledMobileHeadCell>}

                  {cell}
                </StyledBodyCell>
              ))}

            </StyledBodyRow>
          )
        })}
      </StyledBody>
    </StyledRoot>
  )
}