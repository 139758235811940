import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

import { Typography } from './Typography.component'


// Make Highcharts lines smooth
Highcharts.seriesTypes.line.prototype.getPointSpline = Highcharts.seriesTypes.spline.prototype.getPointSpline

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 480px;
  `

export const LinearChart = ({data}) => {
  const { t } = useTranslation()
  const chartWrapperRef = useRef(null)
  const theme = useTheme()
  const [chartWidth, setChartWidth] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      if (chartWrapperRef.current) {
        setChartWidth(chartWrapperRef.current.offsetWidth)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const options = {
    title: {
      text: null,
    },
    legend: {
      enabled: false // Remove legends
    },
    credits: {
      enabled: false // Disable the Highcharts link
    },
    plotOptions: {
      series: {
        color: theme.color.chart, // Set line color to green
        marker: {
          enabled: false // Disable marker
        },
      }
    },
    xAxis: {
      lineWidth: 0,
      tickLength: 0,
      labels: {
        enabled: false
      },
    },
    yAxis: {
      min: data ? Math.min(...data) : 0, // Set y-axis minimum to the minimum value of the data series
      max: data ? Math.max(...data) : 0,  // Set y-axis maximum to the maximum value of the data series
      title: {
        text: null // Remove y-axis label for captions
      },
      gridLineWidth: 0, // Remove horizontal grid lines
      labels: {
        style: {
          color: theme.color.textSecondary // Change color of x-axis text to blue
        }
      },
    },
    // Apply gradient to the plot area
    chart: {
      backgroundColor: 'transparent', // Set background to transparent
      width: chartWidth,
    },
    tooltip: {
      backgroundColor: theme.color.info, // Set the background color here
      style: {
        color: theme.color.textPrimary, // Set the text color here
      },
      formatter: function () {
        return '<b>' + this.series.name + '</b><br/>' + '' + this.y // Customize as needed
      }
    },
    series: [
      {
        name: t('usd'), // Change series title here
        type: 'area',
        fillColor: {
          linearGradient: [0, 100, 0, 300],
          stops: [
            [0, Highcharts.getOptions().colors[2]],
            [1, 'rgba(92,196,137,0.25)']
          ]
        },
        data,
      }
    ],
  }

  if (data?.length) {
    return (
      <StyledWrapper ref={chartWrapperRef}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </StyledWrapper>
    )
  } else {
    return <Typography>{t('chartDataIsEmpty')}</Typography>
  }
}