import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { IconButton } from './IconButton.component'


const PERIOD_24H = '24h'
export const PERIOD_DEFAULT = PERIOD_24H

const now = new Date()

const subtractDays = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() - days)
  result.setUTCHours(0, 0, 0, 0)
  return result
}

export const DATE_PERIOD = {
  [PERIOD_24H]: '24h',
  '7d': '7d',
  '1m': '1m',
  '3m': '3m',
  'all': 'All'
}

export const DATE_PERIOD_TIMESTAMPS = {
  [DATE_PERIOD[PERIOD_24H]]: subtractDays(now, 1).getTime(),
  [DATE_PERIOD['7d']]: subtractDays(now, 7).getTime(),
  [DATE_PERIOD['1m']]: subtractDays(now, 30).getTime(),
  [DATE_PERIOD['3m']]: subtractDays(now, 90).getTime(),
  [DATE_PERIOD['all']]: 1325379602000
}

const StyledRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(${Object.keys(DATE_PERIOD_TIMESTAMPS).length}, 1fr);
  grid-column-gap: 10px;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
  margin-bottom: 20px;
`

export const PeriodSelect = ({ disabled, periodKey, setPeriodKey }) => {
  const { t } = useTranslation()

  return (
    <StyledRoot>
      {Object.keys(DATE_PERIOD_TIMESTAMPS).map((key, index) => (
        <IconButton
          key={index}
          type="button"
          bordered={periodKey === key}
          active={periodKey === key}
          onClick={() => {
            setPeriodKey(key)
          }}
          disabled={disabled}
        >
          {t(key)}
        </IconButton>
      ))}
    </StyledRoot>
  )
}