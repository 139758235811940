import styled from 'styled-components'


const StyledRoot = styled.span`
  position: relative;
  
  display: inline-block;
  width: 32px;
  height: 32px;
  font-size: 32px;
  color: #fff;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 0.75em;
    height: 0.75em;
    margin: 0.125em;
    border: 0.125em solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`

export const Spinner = ({ iconBefore, variant, children, ...restProps }) => {
  return (
    <StyledRoot {...restProps}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledRoot>
  )
}