import { useEffect, useState } from 'react'


export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches)

  useEffect(() => {
    const mediaQuery = window.matchMedia(query)
    const handler = (e) => setMatches(e.matches)

    mediaQuery.addListener(handler)
    setMatches(mediaQuery.matches)

    return () => mediaQuery.removeListener(handler)
  }, [query])

  return matches
}