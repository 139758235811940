import { COLOR } from './color.constant'
import { BORDER_RADIUS } from './border-radius.constant'
import { SCREEN_WIDTH } from './screen-width.constant'
import { Z_INDEX } from './z-index.constant'

export const INVESTOR_THEME = {
  borderRadius: BORDER_RADIUS,
  color: COLOR,
  screenWidth: SCREEN_WIDTH,
  zIndex: Z_INDEX,
}