export const INVESTMENT_CATEGORIES = [
  'blockchainService',
  'defi',
  'cefi',
  'chain',
  'nft',
  'blockchainInfrastructure',
  'social',
  'gamefi',
  'stablecoin',
  'other',
  'currency',
]