import { LANGUAGE } from './language.constant'

export const LANGUAGE_TO_NAME = {
  [LANGUAGE.en]: "English",
  [LANGUAGE.uk]: "Українська",
  [LANGUAGE.ru]: "Русский",
  [LANGUAGE.cs]: "Česky",
  [LANGUAGE.pl]: "Polski",
  [LANGUAGE.es]: "Español",
  [LANGUAGE.pt]: "Português",
  [LANGUAGE.lv]: "Latviesu",
  [LANGUAGE.it]: "Italiano",
  [LANGUAGE.de]: "Deutsch",
  [LANGUAGE.fr]: "Français",
  [LANGUAGE.nl]: "Nederlands"
}