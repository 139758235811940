import { useContext, useState } from 'react'
import styled from 'styled-components'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AuthorizationContext } from '../contexts'
import { InvestorLayout } from '../layouts'
import { Button, Container, Paper, Typography } from '../components'
import { DEFAULT_INVESTOR_ROUTE } from '../routing'


const StyledContainer = styled.div`
  height: 100%;
`

const StyledPaper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  
  background-color: ${({ theme }) => theme.color.backgroundBanned};
`

export const BannedPage = () => {
  const { t } = useTranslation()
  const { me, refetchMe } = useContext(AuthorizationContext)

  const [fetching, setFetching] = useState(false)

  const banned = me?.investor?.banned

  if (!banned) {
    return <Navigate to={DEFAULT_INVESTOR_ROUTE} replace />
  }

  const refreshData = () => {
    setFetching(true)

    refetchMe().finally(() => {
      setFetching(false)
    })
  }

  return (
    <InvestorLayout>
      <Container as={StyledContainer}>
        <Paper as={StyledPaper}>
          <Typography align="center" variant='small-value-dark'>{me.investor.banReason}</Typography>
          <Button loading={fetching} variant="success" onClick={refreshData}>
            {t('refreshData')}
          </Button>
        </Paper>
      </Container>
    </InvestorLayout>
  )
}