import React, { useRef } from 'react'
import styled from 'styled-components'

import { classNames } from '../utils'
import { useMouseMove3dPerspective } from '../hooks'


const RangeWrapper = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.color.success};
  transition: 0.4s;
`

const RangeInput = styled.input`
  -webkit-appearance: none;
  position: relative;
  width: 100%;
  height: 18px;
  margin: 0;
  border-radius: 16px;
  background: #ccc;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: .2s;
  color: inherit;
  transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis));

  &:not([disabled]) {
    &:active {
      transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(1.05);
    }

    &::-webkit-slider-thumb {
      cursor: pointer;
    }

    &::-moz-range-thumb {
      cursor: pointer;
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: currentColor;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }

  &::-moz-range-thumb {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: currentColor;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 16px;
    width: ${props => `calc(${(props.value / props.max) * 100}% + ${8 - (8 * (props.value / props.max))}px)`};
    background-color: currentColor;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
`

export const InputRange = ({ value, min, max, step, name, onChange, ...restProps }) => {
  const rootRef = useRef();
  useMouseMove3dPerspective(rootRef);

  return (
    <RangeWrapper ref={rootRef} className={classNames({ disabled: restProps.disabled })} {...restProps}>
      <RangeInput
        type="range"
        disabled={restProps.disabled}
        name={name}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
      />
    </RangeWrapper>
  )
}