import styled from 'styled-components'


const StyledRoot = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1024px;
  padding: 20px;
  margin: 0 auto;
`

export const Container = ({ as, children }) => {
  return (
    <StyledRoot as={as}>
      {children}
    </StyledRoot>
  )
}