const TOKEN_KEY = 'token';
const ME_KEY = 'me';

export const setLocalStorageToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
}

export const getLocalStorageToken = () => {
  return localStorage.getItem(TOKEN_KEY);
}

export const removeLocalStorageToken = () => {
  localStorage.removeItem(TOKEN_KEY);
}

export const setLocalStorageMe = (user) => {
  localStorage.setItem(ME_KEY, JSON.stringify(user));
}

export const getLocalStorageMe = () => {
  try {
    return JSON.parse(localStorage.getItem(ME_KEY));
  } catch (error) {
    return null;
  }
}

export const removeLocalStorageMe = () => {
  localStorage.removeItem(ME_KEY);
}
