import { useRef } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import { ButtonBack, Header, NavBar } from '../components'
import { useMediaQuery, useMouseMoveGradientBackground } from '../hooks'
import { SCREEN_WIDTH } from '../theme/screen-width.constant'


const StyledRoot = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
`

const StyledBody = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }
`

const StyledContent = styled.main`
  position: relative;
  padding: 40px 30px;

  animation: example 0.2s forwards;

  @keyframes example {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    padding: 20px;
  }
`

export const AdminLayout = ({ parentRoute, children }) => {
  const contentRef = useRef()
  const isMobileScreen = useMediaQuery(`(max-width: ${SCREEN_WIDTH.mobile})`)
  useMouseMoveGradientBackground(contentRef, 0.06)

  return (
    <StyledRoot>
      <Helmet>
        <title>HolaBoss</title>
      </Helmet>

      <Header />

      <StyledBody>

        {!isMobileScreen && <NavBar/>}

        <StyledContent ref={contentRef}>
          {parentRoute && <ButtonBack routeBack={parentRoute}/>}
          {children}
        </StyledContent>

        {isMobileScreen && <NavBar/>}
      </StyledBody>
    </StyledRoot>
  )
}