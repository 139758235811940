import styled from 'styled-components'

import { useEffect, useRef, useState } from 'react'
import { useFocusControl } from '../hooks'


const StyledRoot = styled.div`
  position: relative;

  &.shown {
    & > .body {
      transform: translate(0, 100%);
      opacity: 1;
      pointer-events: all;
    }
  }
`
const StyledBody = styled.div`
  position: absolute;
  bottom: -10px;
  right: 0;

  padding: 10px 20px;
  border-radius: ${({ theme }) => theme.borderRadius.menu};

  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);

  transition: 0.3s;

  transform: translate(-100%, 100%);
  opacity: 0;
  pointer-events: none;
`

export const Select = ({ controlComponent, children }) => {
  const rootRef = useRef(null)
  const bodyRef = useRef(null)

  const [shown, setShown] = useState(false)

  useFocusControl(bodyRef, shown)

  useEffect(() => {
    if (!shown) {
      return
    }

    const listenerEscape = (event) => {
      if (event.key === 'Escape') {
        close()
        event.stopPropagation()
      }
    }

    const listenerClickOutside = (event) => {
      const clickedInside = rootRef.current.contains(event.target)
      if (!clickedInside) {
        close()
      }
    }

    document.addEventListener('click', listenerClickOutside)
    rootRef.current.addEventListener('keydown', listenerEscape)

    return () => {
      document.removeEventListener('click', listenerClickOutside)
      rootRef.current?.removeEventListener('keydown', listenerEscape)
    }
  }, [shown])

  const toggle = () => {
    setShown(!shown)

    if (shown) {
      document.activeElement.blur()
    }
  }

  const close = () => setShown(false)

  return (
    <StyledRoot ref={rootRef} className={shown ? 'shown' : ''}>
      {controlComponent({ shown, toggle })}

      <StyledBody ref={bodyRef} className="body">{children}</StyledBody>
    </StyledRoot>
  )
}