import styled from 'styled-components'
import { Dialog } from './Dialog.component'
import { Typography } from './Typography.component'
import { Button } from './Button.component'


const StyledRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
`

const StyledButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
`

export const ConfirmationDialog = ({
                                     text,
                                     cancelText,
                                     confirmText,
                                     isDangerConfirm,

                                     shown,
                                     onConfirm,
                                     onCancel,
                                     onClose,

                                     children
                                   }) => {
  return (
    <Dialog shown={shown} onClose={onClose}>
      <StyledRoot>
        <Typography align='center' variant="paper-title">{text}</Typography>
        {children}
        <StyledButtons>
          <Button variant="info" onClick={onCancel}>{cancelText}</Button>
          <Button variant={isDangerConfirm ? 'danger' : 'warning'} onClick={onConfirm} data-focusable>{confirmText}</Button>
        </StyledButtons>
      </StyledRoot>
    </Dialog>
  )
}