export const COLOR = {
  backgroundPrimary: '#000',
  backgroundSecondary: '#232530',
  backgroundTertiary: '#1A1A1A',

  backgroundBanned: '#0E1421',

  border: '#232530',

  textPrimary: '#fff',
  btnText: '#fff',
  textPrimaryHover: '#3B82F6',

  textSecondary: '#A3A3A3',

  success: '#1FDE00',
  danger: '#FF0000',
  warning: '#3B82F6',
  info: '#494949',

  chart: '#5CC489',
}