import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'


const StyledRoot = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  grid-template-columns: 1fr;
  height: 100vh
`

const StyledTitle = styled.h1`
  font-size: 66px;
  font-weight: 900;
  margin: 0;
`

const StyledSubtitle = styled.div`
  font-size: 26px;
  font-weight: 300;
`

export const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <StyledRoot>
      <Helmet>
        <title>Not Found</title>
      </Helmet>

      <StyledTitle>404</StyledTitle>

      <StyledSubtitle>{t('pageNotFound')}</StyledSubtitle>
    </StyledRoot>
  )
}