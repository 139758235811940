import { useEffect } from 'react'


export const useMouseMove3dPerspective = (elementRef) => {
  useEffect(() => {
    const element = elementRef.current;
    if (!element) {
      return;
    }

    function handleHover(e) {
      const { offsetX, offsetY, currentTarget } = e;
      const { clientWidth, clientHeight } = currentTarget;

      let xAxis = (clientWidth / 2 - offsetX) * 3;
      let yAxis = (clientHeight / 2 - offsetY) * 3;

      let negativeX = xAxis < 0;
      let negativeY = yAxis < 0;

      xAxis = Math.min(Math.abs(xAxis), 4) * (negativeX ? -1 : 1);
      yAxis = Math.min(Math.abs(yAxis), 4) * (negativeY ? -1 : 1);

      element.style.setProperty('--xAxis', xAxis + 'deg');
      element.style.setProperty('--yAxis', yAxis + 'deg');
      element.style.setProperty('--perspective', clientWidth * 2 + 'px');
    }

    function resetStyles() {
      element.style.setProperty('--xAxis', 0 + 'deg');
      element.style.setProperty('--yAxis', 0 + 'deg');
    }

    element.addEventListener('mousemove', handleHover);
    element.addEventListener('mouseleave', resetStyles);

    return () => {
      element.removeEventListener('mousemove', handleHover);
      element.removeEventListener('mouseleave', resetStyles);
    }
  }, [])
}