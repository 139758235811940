import { makeRequest } from './make-request.api'


export const getMyPortfolioRequest = ({ forceRefresh, } = {}) => {
  const params = new URLSearchParams()

  if (forceRefresh) {
    params.set('forceRefresh', 'true')
  }

  const queryStr = '?' + params.toString()
  return makeRequest('/portfolio' + queryStr)
}

export const refreshAllPortfoliosRequest = () => {
  return makeRequest('/portfolio/refresh-all', {
    method: 'POST',
  })
}

export const refreshUserPortfolioRequest = ({ userId, }) => {
  return makeRequest(`/portfolio/refresh/${userId}`, {
    method: 'POST',
  })
}