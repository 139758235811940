import { useEffect } from 'react'


export const useFocusControl = (ref, canFocus, withScroll) => {
  useEffect(() => {
    let previousFocusedElement

    if (canFocus) {
      setTimeout(() => {
        previousFocusedElement = document.activeElement
        let elementToFocus = ref.current

        const focusElements = [
          ...ref.current?.querySelectorAll('input:not([disabled]), select:not([disabled]), textarea:not([disabled])'),
          ...ref.current?.querySelectorAll('[data-focusable]'),
          ...ref.current?.querySelectorAll('button:not([disabled]), [href]')
        ]

        for (const element of focusElements) {
          const closestFocusableContainer = element.closest('[tabindex="0"]')
          if (!closestFocusableContainer || closestFocusableContainer === ref.current) {
            elementToFocus = element
            break
          }
        }

        elementToFocus.focus({
          preventScroll: true
        })

        if (withScroll) {
          elementToFocus.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      })
    }

    return () => {
      previousFocusedElement?.focus()
    }
  }, [canFocus])
}