import { useEffect } from 'react'


export const useMouseMoveGradientBackground = (elementRef, alpha = 0.08) => {
  useEffect(() => {
    const $body = elementRef.current

    function mouseMove(event) {
      requestAnimationFrame(() => {
        const pageWidth = $body.offsetWidth
        const pageHeight = $body.offsetHeight
        const x = (event.clientX / pageWidth) * 2 - 1
        const y = (event.clientY / pageHeight) * 2 - 1
        const distance = Math.max(Math.abs(x), Math.abs(y))

        const lightAngleRadians = Math.atan2(y, x)
        const lightAngle = lightAngleRadians * (180 / Math.PI) - 90
        const lightDistance = 20 * distance + 80

        $body.style['background-image'] = `linear-gradient(${lightAngle}deg, rgba(0, 255, 0, ${alpha}) 0%, rgba(0,0,0,0) ${lightDistance}%)`
      })
    }

    document.addEventListener('mousemove', mouseMove)

    return () => {
      document.removeEventListener('mousemove', mouseMove)
    }
  }, [])
}