import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { AuthorizationProvider } from './contexts'
import { App } from './App'

import 'react-toastify/dist/ReactToastify.css'

import './translates/i18n'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Router>
    <AuthorizationProvider>
      <App/>
    </AuthorizationProvider>
    <ToastContainer/>
  </Router>
)