import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

import { Typography } from './Typography.component'


// Make Highcharts lines smooth
Highcharts.seriesTypes.line.prototype.getPointSpline = Highcharts.seriesTypes.spline.prototype.getPointSpline

const StyledRoot = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`

export const DonutChart = ({ data, onPointMouseOver, onPointClick }) => {
  const { t } = useTranslation()

  const chartRef = useRef(null)
  const chartWrapperRef = useRef(null)

  const [chartSize, setChartSize] = useState(null)

  let allowPointSelect = false

  // Allow point select if there are more than one non-zero values
  for (let i = 0, nonZeroValueCount = 0; i < data.length; i++) {
    if (data[i].y > 0) {
      nonZeroValueCount++
    }
    if (nonZeroValueCount > 1) {
      allowPointSelect = true
      break
    }
  }

  // Set chart size on mount and on window resize
  useEffect(() => {
    const handleResize = () => {
      if (chartWrapperRef.current) {
        setChartSize(Math.min(320, chartWrapperRef.current.offsetWidth))
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Workaround for the known issue – "Updating Highcharts points events":
  // https://github.com/highcharts/highcharts-react/issues/112
  useEffect(() => {
    const series = chartRef.current.chart.series[0]

    series.points.forEach((point) => {
      Highcharts.removeEvent(point.graphic.element, 'mouseover')
      Highcharts.removeEvent(point.graphic.element, 'click')
      Highcharts.addEvent(point.graphic.element, 'mouseover', function (event) {
        if (!allowPointSelect) {
          return false
        }

        point.select(true, false)
        onPointMouseOver(point)
      })
      Highcharts.addEvent(point.graphic.element, 'click', function (event) {
        if (!allowPointSelect) {
          return false
        }

        if (!point.selected) {
          point.select(true, false)
        } else {
          point.select(false, false)
        }
        onPointClick(point)
      })
    })
  }, [allowPointSelect])

  const options = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent', // Set background to transparent
      width: chartSize,
      height: chartSize,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false // Disable the Highcharts link
    },
    series: [{
      name: 'Brands',
      colorByPoint: true,
      data,
    }],
    plotOptions: {
      pie: {
        innerSize: '65%',
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        }
      },
      series: {
        point: {
          selectable: allowPointSelect,
        }
      }
    },
    tooltip: {
      enabled: false
    }
  }

  if (data.length) {
    return (
      <StyledRoot ref={chartWrapperRef}>
        <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options}/>
      </StyledRoot>
    )
  } else {
    return <Typography>{t('chartDataIsEmpty')}</Typography>
  }
}