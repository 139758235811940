import { useRef } from 'react'
import styled from 'styled-components'

import { useMouseMove3dPerspective } from '../hooks'


const StyledRoot = styled.button`
  transition: 0.15s;
  transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis));

  @media (hover: hover) {
    &:hover {
      transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(1.05);
    }
  }
  
  &:active {
    transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(0.98);
  }
`

export const LegendItem = (props) => {
  const rootRef = useRef()
  useMouseMove3dPerspective(rootRef)

  return (
    <StyledRoot {...props} ref={rootRef} />
  )
}