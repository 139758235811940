import { useRef } from 'react'
import styled from 'styled-components'

import { Spinner } from './Spinner.component'
import { classNames } from '../utils'
import { useMouseMove3dPerspective } from '../hooks'


const StyledRoot = styled.button`
  min-width: 280px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.formElement};
  
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;

  color: ${({ theme }) => theme.color.btnText};
  outline: none;

  cursor: pointer;
  transition: 0.2s;
  user-select: none;

  &:not([disabled]) {
    transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis));

    &:focus {
      transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(0.99);
    }

    @media (hover: hover) {
      &:hover {
        transform: perspective(var(--perspective)) rotateX(var(--xAxis)) rotateY(var(--yAxis)) scale(1.05);
        filter: brightness(0.8);
      }
    }
    
    &:active {
      transform: scale(0.98);
    }
  }
  
  // COLOR
  &.success {
    background-color: ${({ theme }) => theme.color.success}
  }

  &.danger {
    background-color: ${({ theme }) => theme.color.danger}
  }

  &.warning {
    background-color: ${({ theme }) => theme.color.warning}
  }

  &.info {
    background-color: ${({ theme }) => theme.color.info}
  }

  &.white {
    background-color: ${({ theme }) => theme.color.textPrimary};
    color: ${({ theme }) => theme.color.backgroundPrimary};
  }
  
  &.small {
    font-size: 14px;
    min-width: 176px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  
  &.loading {
    cursor: wait;
    filter: brightness(0.8);
    transform: scale(0.98);
  }

  &[disabled] {
    filter: brightness(0.8);
    cursor: not-allowed;
  }
  
  .spinner {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: currentColor;
  }
  
  svg {
    fill: currentColor;
  }

  @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
    &, &.small {
      min-width: unset;
    }
    
    font-size: 16px;

    .spinner {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }
`

const StyledIconBefore = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
`

/**
 *
 * @param {boolean} small
 * @param {boolean} loading
 * @param iconBefore
 * @param {'success' | 'danger' | 'warning' | 'info' | 'white'} variant
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const Button = ({ small, loading, iconBefore, variant, children, ...restProps }) => {
  const rootRef = useRef()
  useMouseMove3dPerspective(rootRef)

  return (
    <StyledRoot className={classNames(variant, { small, loading })} disabled={loading} {...restProps} ref={rootRef}>
      {iconBefore && <StyledIconBefore>{iconBefore}</StyledIconBefore>}
      {loading ? <Spinner className='spinner'/> : children}
    </StyledRoot>
  )
}