import { useEffect } from 'react'


export const usePutBackScrollPosition = (shown) => {
  useEffect(() => {
    if (shown) {
      const scrollY = window.scrollY

      return () => {
        window.scrollTo(0, scrollY)
      }
    }
  }, [shown])
}