import { useRef } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import { Header } from '../components'
import { useMouseMoveGradientBackground } from '../hooks'


const StyledRoot = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
`

const StyledContent = styled.div`
  box-sizing: border-box;
  max-width: 1360px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
`

export const NonAuthorizedLayout = ({ contentAs, children }) => {
  const contentRef = useRef()
  useMouseMoveGradientBackground(contentRef)

  return (
    <StyledRoot>
      <Helmet>
        <title>DOV Invest Crypto closed-end fund</title>
      </Helmet>

      <Header />

      <StyledContent ref={contentRef} as={contentAs}>
        {children}
      </StyledContent>
    </StyledRoot>
  )
}