import styled from 'styled-components'

import { Modal } from './Modal.component'
import { Paper } from './Paper.component'


const StyledRoot = styled.div`
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  max-width: 700px;
  padding: 55px;
`

export const Dialog = ({ shown, onClose, children }) => {
  return (
    <Modal noCloseButton shown={shown} onClose={onClose}>
      <Paper as={StyledRoot}>
        {children}
      </Paper>
    </Modal>
  )
}