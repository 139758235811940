import styled from 'styled-components'


const StyledRoot = styled.hr`
  margin: 16px 0;
  height: 2px;
  border: none;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.info};

  &.big {
    height: 4px;
    background-color: ${({ theme }) => theme.color.backgroundSecondary};
  }
`

/**
 *
 * @param {'default' | 'big'} size
 * @returns {JSX.Element}
 * @constructor
 */
export const HorizontalDivider = ({ size }) => {
  return (
    <StyledRoot className={size} />
  )
}