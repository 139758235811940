import styled from 'styled-components'

import { classNames } from '../utils'


const StyledRoot = styled.span`
  word-break: break-word;
  
  // VARIANTS
  &.auth-title {
    font-size: 36px;
    font-weight: 900;
    color: ${({ theme }) => theme.color.textPrimary};

    @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
      font-size: 30px;
    }
  }

  &.label-bold {
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.textPrimary};

    @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
      font-size: 16px;
    }
  }

  &.label {
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.textSecondary};
  }

  &.small-value-bold {
    font-size: 22px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.textPrimary};
  }

  &.small-value-dark {
    font-size: 22px;
    font-weight: 900;
    color: ${({ theme }) => theme.color.textPrimary};
  }

  &.big-value {
    font-size: 35px;
    font-weight: 900;
    color: ${({ theme }) => theme.color.textPrimary};

    @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
      font-size: 30px;
    }
  }

  &.small-caption {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.textSecondary};
  }

  &.big-caption {
    font-size: 24px;
    font-weight: 900;
    color: ${({ theme }) => theme.color.textSecondary};

    @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
      font-size: 18px;
    }
  }

  &.info-message {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.textSecondary};
  }

  &.page-title {
    font-size: 30px;
    font-weight: 900;
    color: ${({ theme }) => theme.color.textPrimary};
    
    @media (max-width: ${({ theme }) => theme.screenWidth.mobile}) {
      font-size: 22px;
    }
  }

  &.paper-title {
    font-size: 20px;
    font-weight: 900;
    color: ${({ theme }) => theme.color.textPrimary};
  }

  &.section-title {
    font-size: 50px;
    font-weight: 900;
  }
  
  &.section-description {
    font-size: 18px;
    font-weight: 500;
  }

  // COLORS
  &.color-success {
    color: ${({ theme }) => theme.color.success};
  }

  &.color-danger {
    color: ${({ theme }) => theme.color.danger};
  }
  
  &.color-primary {
    color: ${({ theme }) => theme.color.textPrimary};
  }
  
  // ALIGN
  &.left {
    display: block;
    text-align: left;
  }
  
  &.center {
    display: block;
    text-align: center;
  }
  
  &.right {
    display: block;
    text-align: right;
  }
`

const COLOR_MAP = {
  'success': 'color-success',
  'danger': 'color-danger',
  'primary': 'color-primary',
}

/**
 *
 * @param {'auth-title' | 'label-bold' | 'label' | 'small-value-bold' | 'small-value-dark' | 'big-value' | 'small-caption' | 'big-caption' | 'info-message' | 'page-title' | 'paper-title' | 'section-title' | 'section-description'} variant
 * @param {'success' | 'danger' | 'primary'} color
 * @param {'left' | 'center' | 'right'} align
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const Typography = ({ variant, color, align, ...restProps }) => {
  return (
    <StyledRoot className={classNames(variant, COLOR_MAP[color], align)} {...restProps} />
  )
}